import { request } from "utils/axios";

export const getCategories = async () => {
    try {
        const res = await request.get('categories');
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'something went wrong');
        throw error;
    }
};

export const products = async () => {
    try {
        const res = await request.get('products?limit=20&page=1');
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
};

export const getCoupon = async (value) => {
    try {
        const res = await request.get(`coupons/${value}`);
        return res?.data;
    } catch (err) {
        throw err;
    }
}

export const product = async (uuid) => {
    try {
        const res = await request.get(`products/${uuid}`);
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings');
        return error;
    }
};

export const getHomes = async () => {
    try {
        const res = await request.get(`homes`);
        console.log(res)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings wrong at address');
        return error;
    }
};

export const createAddress = async (value) => {
    try {
        const res = await request.post(`users/addresses`, value);
        console.log(res)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings wrong creating address');
        return error;
    }
}

export const getAddress = async () => {
    try {
        const res = await request.get(`users/addresses`);

        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings wrong creating address');
        return error;
    }
}

export const deleteAddress = async (id) => {
    try {
        const res = await request.delete(`users/addresses/${id}`);
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings wrong creating address');
        return error;
    }
};


export const dispenserRepair = async (value) => {
    try {
        const res = await request.post(`dispensers`, value);
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings wrong creating address');
        return error;
    }
};

export const vip = async (value) => {
    try {
        const res = await request.post(`users/vip`, value);
        return res?.data;
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings wrong creating address');
        return error;
    }
};

export const getVip = async () => {
    try {
        const res = await request.get(`users/vip`);
        return res?.data;
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings wrong creating address');
        return error;
    }
};


export const initPayment = async (value) => {
    try {
        const res = await request.post(`payments/init`, value);
        return res?.data;
    } catch (err) {
        throw err;
    }
}


export const verifyPayment = async (value) => {
    try {
        const res = await request.post(`payments/verify`, value);
        return res?.data;
    } catch (err) {
        throw err;
    }
}

export const searchProduct = async (value) => {
    try {
        const res = await request.get(`search?query=${value}`);
        return res?.data;
    } catch (err) {
        throw err;
    }
}

export const fileUpload = async (formData) => {
    try {
        const res = await request.post(`uploads`, formData);
        return res?.data;
    } catch (error) {
        console.log(error?.response?.data)
        console.log(error?.response?.data?.message, "something's wrong uploading image");
        return error;
    }
};
