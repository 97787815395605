import {
  updateProfile as profile,
  register,
  userLogin,
  verifyAccount,
  resetPassword,
  forgotPassword,
  ResendCode,
} from "services/auth";
import {
  LOG_OUT,
  LOGIN_USER,
  AUTH_START,
  AUTH_ERROR,
  REGISTER_USER,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  VERIFY_ACCOUNT_START,
  VERIFY_ACCOUNT_ERROR,
  VERIFY_ACCOUNT_SUCCESS,
  USER_OBJ_UPDATE,
  RESEND_OTP
} from "../actions.types";
import toast from "react-hot-toast";
import {
  setAuthToken, clearToken, getCart,
  clearCart, getLocationHistory, setDeliveryFee
} from "utils/helpers";
import { addManyCart } from "services/carts";


export const registerUser = async (values) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_START });
    const res = await register(values);
    dispatch({ type: REGISTER_USER });
    toast.success(res?.message);
    window.open("/login", "_self");
  } catch (err) {

    dispatch({ type: AUTH_ERROR });
    Object.keys(err?.response?.data?.error).map(error => toast.error(err?.response?.data?.error[error][0]))
  }
};

export const loginUser = async (values) => async (dispatch) => {
  try {

    dispatch({ type: AUTH_START });
    const res = await userLogin(values);
    console.log(res.data?.fee);
    setDeliveryFee(res.data?.fee);
    dispatch({ type: LOGIN_USER });
    const checkCart = getCart();
    const items = checkCart?.map((data) => ({ quantity: data?.quantity, product_id: data?.product?.id }))
    if (res?.status) {
      console.log(res)
      toast.success(res?.message);
      setAuthToken(res?.data?.accessToken);
      if (checkCart !== null) {
        const addMany = await addManyCart({
          items: items
        })
        clearCart();
        if (getLocationHistory() !== null) {
          window.open(getLocationHistory(), "_self")
        } else {
          window.open("/", "_self");
        }
      }
      window.open("/", "_self");
    }



  } catch (err) {
    dispatch({ type: AUTH_ERROR });
    if (err?.response?.data?.error) {
      toast.error(err?.response?.data?.error?.phone ?? 'There was an error with your network connection')
    } else {
      toast.error(err?.response?.data?.message ?? 'There was error with your network connection')
    }
    if (err?.response?.data?.verified === false) {
      window.open("/login", "_self");
    }
    // toast.error(err?.response?.data?.error?.username[0] ?? err?.response?.data?.message);
  }
};

export const userProfile = async () => async (dispatch) => {
  try {
    dispatch({ type: AUTH_START });
    const res = await profile();
    dispatch({ type: GET_USER_PROFILE, payload: res?.data });

  } catch (err) {
    dispatch({ type: AUTH_ERROR });
    // toast.error(err?.response?.data?.message ?? "Error Loading User Info")
  }
};

export const updateProfile = async (value) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_START });
    const res = await profile(value);
    dispatch({ type: UPDATE_USER_PROFILE, payload: res?.data });
    toast.success(res?.message);
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
    toast.error(
      err?.response?.data?.message ??
      "There seems to be a problem updating profile",
    );
  }
};

export const passwordReset = async (values) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_START });
    const res = await resetPassword(values);
    dispatch({ type: RESET_PASSWORD });
    toast.success(res?.message);
    window.open("/login", "_self");
    return res?.data;
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
    toast.error(err?.response?.data?.message ?? "Password reset failed");
  }
};

export const forgotUserPassword = async (values) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_START });
    const res = await forgotPassword(values);
    dispatch({ type: FORGOT_PASSWORD });
    toast.success(res?.message);
    window.open("/reset-password", "_self");
    return res?.data;
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
    toast.error(err?.response?.data?.message ?? "Error");
  }
};

export const verifyAcct = async (values) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_ACCOUNT_START });
    const res = await verifyAccount(values);
    dispatch({ type: VERIFY_ACCOUNT_SUCCESS });
    toast.success(res?.message);
    window.open("/login", "_self");
  } catch (err) {
    dispatch({ type: VERIFY_ACCOUNT_ERROR });
    toast.error(err?.response?.data?.message ?? "Error");
  }
};

export const resendOtpCode = async (values) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_START });
    const res = await ResendCode(values);
    dispatch({ type: RESEND_OTP });
    toast.success(res?.message);
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
    toast.error(err?.response?.data?.message ?? "Error");
  }
};

export const logOut = () => (dispatch) => {
  dispatch({ type: LOG_OUT });
  clearToken();
  window.open("/", "_self");
};

export const updateUserObjAction = async (value) => async (dispatch) => {
  dispatch({
      type: USER_OBJ_UPDATE,
      payload: value,
  });
};
