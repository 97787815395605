import image from 'assets/images/hero.svg'; // An image for the Carousel slideshow
import { useActivity } from 'hooks/useBusiness.js';
import React, { useState, useEffect } from "react"; // Importing react Hooks
import { Wrapper, Container, Img, Progress, Tab as ProgBtn, Arrow, Btn } from './style.js'; // Importing the Carousel Styled Tags
import { useNavigate } from 'react-router-dom';





// This component accepts the contents array as it's prop
const Carousel = ({ position = 'top' }) => {
    // States
    const { state } = useActivity()
    const [time, setTime] = useState(null); // to capture the timeout signature
    const [index, setIndex] = useState(0); //This holds the index of the current carouset image
    const [data, setData] = useState(state?.adverts); // This holds the data of the content prop passed or sets a default array of of five objects

    // Methods
    const Front = () => setIndex(prev => state?.adverts?.filter((data) => data?.medias[0].platform === 'web' && data?.medias[0].placement === position).length ? ++prev % state?.adverts?.filter((data) => data?.medias[0].platform === 'web' && data?.medias[0].placement === position).length : 0); // This increments the current index by one
    const Back = () => setIndex(prev => prev === 0 ? state?.adverts?.filter((data) => data?.medias[0].platform === 'web' && data?.medias[0].placement === position).length - 1 : --prev); // This decrements the current index by one and if is less than 0 returns the number of data length minus one
    const navigate = useNavigate();

    // Effects
    useEffect(() => {
        //setData(state?.adverts);
    }, []); // Invokes immediately when the component loads
    useEffect(() => {

        clearTimeout(time); setTime(setTimeout(Front, 6000))
    }, [index, state?.adverts]); // invokes whenever data or index changes, it clears the timeout and sets another timeout after a given time

    // Carousel Component
    return <Wrapper>
        <Container>
            {state?.adverts?.filter((data) => data?.medias[0].platform === 'web' && data?.medias[0].placement === position).map((item, id) => <Img onClick={() => window.open(item.link)} src={item?.medias[0].filename}
                index={index} key={id} />)} {/* Array of images from data state */}
            <Arrow>
                <Btn onClick={Back} dir='left' /> {/** This invokes the Back method */}
                <Btn onClick={Front} dir='right' /> {/** This invokes the Front method */}
            </Arrow>
        </Container>
        {position === 'top' && <Progress>
            {state?.adverts?.filter((data) => data?.medias[0].platform === 'web' && data?.medias[0].placement === position).map((_, id) => <ProgBtn current={index === id} onClick={() => setIndex(id)} key={id} />)} {/** The progress buttons, invokes the Immediate button to change the index value */}
        </Progress>}
    </Wrapper>;
};


export default Carousel;
