import { request } from "utils/axios";


export const addCart = async (value) => {
    try {
        console.log(value)
        const res = await request.post('carts', value);
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
};


export const getCarts = async () => {
    try {
        const res = await request.get('carts');
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
};

export const updateCartItem = async (id, value) => {
    try {
        const res = await request.put(`carts/items/${id}`, value);
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
}

export const deletCart = async (id) => {
    try {
        const res = await request.delete(`carts/items/${id}`);
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
}

export const clearCart = async (id) => {
    try {
        const res = await request.delete(`carts/${id}`);
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
}

export const addManyCart = async (value) => {
    try {
        const res = await request.post(`carts/add-many`, value);
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
}

export const addToFav = async (value) => {
    try {
        const res = await request.post(`users/wishlist`, value);
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
}

export const getFav = async (value) => {
    try {
        const res = await request.get(`users/wishlist`, value);
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
}

export const deleteFav = async (uuid) => {
    try {
        const res = await request.delete(`users/wishlist/${uuid}`);
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
}