import { getWallet, getTransactions } from 'store/actions/wallet'

import { useSelector, useDispatch } from 'react-redux';

export const useWallet = () => {
   const dispatch = useDispatch();
   const state = useSelector(({ wallet }) => wallet)

   const fetchWallet = () => {
      dispatch(getWallet())

   }
   const fetchTransactions = (id, page) => dispatch(getTransactions(id, page))

   return { state, fetchWallet, fetchTransactions };
}