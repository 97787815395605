import { lazy } from "react";
import WithLayout from "components/layout";
import WithDashboardLayout from "components/layout/dashboard";

const Faq = lazy(() => import("pages/Faq"));
const Home = lazy(() => import("pages/Home"));
const Test = lazy(() => import("pages/TestPage"));
const Product = lazy(() => import('pages/Product'));
const Login = lazy(() => import("pages/Auth/Login"));
const SignUp = lazy(() => import("pages/Auth/signup"));
const Verify = lazy(() => import("pages/Auth/verify"));
const CheckOut = lazy(() => import("pages/CheckOut"));
const YourCart = lazy(() => import("pages/YourCart"));
const Favourites = lazy(() => import("pages/Wishlist"));
const Wallet = lazy(() => import("pages/UserProfile/Wallet"));
const Address = lazy(() => import("pages/UserProfile/address"));
const Referal = lazy(() => import("pages/UserProfile/Referal"));
const OrderList = lazy(() => import("pages/UserProfile/Orders"));
const ResetPassword = lazy(() => import("pages/Auth/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/Auth/Forgotpassword"));
const OrderDetails = lazy(() => import("pages/UserProfile/Orders/Detail"));
const AccountInfo = lazy(() => import("pages/UserProfile/AccountInformation"));
const ChangePassword = lazy(() => import("pages/UserProfile/ChangePassword"));
const Store = lazy(() => import("pages/Category/store"));
const Beverage = lazy(() => import("pages/Category/beverage"));
const StorePage = lazy(() => import("pages/Category/dispenser"));
const Water = lazy(() => import("pages/Category/water"));
const DispenserRepair = lazy(() => import("pages/UserProfile/DispenserRepair"))
const VIP = lazy(() => import("pages/UserProfile/VIP"));
const NotFound = lazy(() => import("pages/NotFound"));
const Receipt = lazy(() => import("pages/UserProfile/Orders/receipt"));
const VerifyPayment = lazy(() => import("pages/UserProfile/Payment/verify"));




const routes = [
  {
    path: "/login",
    component: WithLayout(Login),
    protected: false,
    exact: true,
  },
  {
    path: "/",
    component: WithLayout(Home),
    protected: false,
    exact: true,
  },
  {
    path: "/register",
    component: WithLayout(SignUp),
    protected: false,
    exact: true,
  },
  {
    path: "/verify",
    component: WithLayout(Verify),
    protected: false,
    exact: true,
  },
  {
    path: "/forgot-password",
    component: WithLayout(ForgotPassword),
    protected: false,
    exact: true,
  },
  {
    path: "/reset-password",
    component: WithLayout(ResetPassword),
    protected: false,
    exact: true,
  },
  {
    path: "/test",
    component: WithLayout(Test),
    protected: false,
    exact: true,
  },
  {
    path: "/product/:cateegory/:id",
    component: WithLayout(Product),
    protected: false,
    exact: true,
  },
  {
    path: "/checkout",
    component: WithLayout(CheckOut),
    protected: true,
    exact: true,
  },
  {
    path: "/cart",
    component: WithLayout(YourCart),
    protected: false,
    exact: true,
  },
  {
    path: "/account-info",
    component: WithDashboardLayout(AccountInfo),
    protected: true,
    exact: true,
  },
  {
    path: "/orders",
    component: WithDashboardLayout(OrderList),
    protected: true,
    exact: true,
  },
  {
    path: "/orders/:id",
    component: WithDashboardLayout(OrderDetails),
    protected: true,
    exact: true,
  },
  {
    path: "/orders/:id/receipt",
    component: WithDashboardLayout(Receipt),
    protected: true,
    exact: true,
  },
  {
    path: "/wallet",
    component: WithDashboardLayout(Wallet),
    protected: true,
    exact: true,
  },
  {
    path: "/address",
    component: WithDashboardLayout(Address),
    protected: true,
    exact: true,
  },
  {
    path: "/change-password",
    component: WithDashboardLayout(ChangePassword),
    protected: true,
    exact: true,
  },
  {
    path: "/referral-code",
    component: WithDashboardLayout(Referal),
    protected: false,
    exact: true,
  },
  {
    path: "/favorites",
    component: WithLayout(Favourites),
    protected: true,
    exact: true,
  },
  {
    path: "/category/:name/:id",
    component: WithLayout(Store),
    protected: false,
    exact: true,
  },
  {
    path: "/beverage",
    component: WithLayout(Beverage),
    protected: false,
    exact: true,
  },
  {
    path: "/store",
    component: WithLayout(StorePage),
    protected: false,
    exact: true,
  },
  {
    path: "/water",
    component: WithLayout(Water),
    protected: false,
    exact: true,
  },
  {
    path: "/payment-verification",
    component: WithDashboardLayout(VerifyPayment),
    protected: false,
    exact: true,
  },
  {
    path: "/dispenser-repair",
    component: WithDashboardLayout(DispenserRepair),
    protected: true,
    exact: true,
  },
  {
    path: "/VIP",
    component: WithDashboardLayout(VIP),
    protected: true,
    exact: true,
  },
  {
    path: "/faq",
    component: WithLayout(Faq),
    protected: false,
    exact: true,
  },
  {
    path: "*",
    component: NotFound,
    protected: false,
    exact: true,

  },
];

export default routes;
