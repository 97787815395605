import {
  logOut,
  passwordReset,
  updateUserObjAction,
  loginUser as login,
  forgotUserPassword,
  verifyAcct as verify,
  userProfile as profile,
  updateProfile as update,
  registerUser as register,
  resendOtpCode as resend,
} from "store/actions/auth";
import { useSelector, useDispatch } from "react-redux";

export const useAuth = () => {
  // Hook to get the current state of the store and dispatch actions
  const dispatch = useDispatch();
  const stateAuth = useSelector((state) => state.auth);

  // Action Dispatchers methods for the useAuth hook
  const logUserOut = () => dispatch(logOut());

  const userProfile = () => dispatch(profile());

  const loginUser = (values) => dispatch(login(values));

  const reset = (values) => dispatch(passwordReset(values));

  const verifyAccount = (values) => dispatch(verify(values));

  const resendOTP = (values) => dispatch(resend(values));

  const updateProfile = (values) => dispatch(update(values));
  
  const registerUser = (values) => dispatch(register(values));

  const forgotPass = (values) => dispatch(forgotUserPassword(values));
  
  const updateUserObj = (values) => dispatch(updateUserObjAction(values));

  return {
    reset,
    stateAuth,
    loginUser,
    resendOTP,
    logUserOut,
    forgotPass,
    userProfile,
    registerUser,
    verifyAccount,
    updateProfile,
    updateUserObj,
  };
};
