// import ButtonLoader from "@components/buttonLoader";
import React from "react";
import { NewButtonContainer } from "./newButton.styled";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined"


const NewButton = ({
  onClick = () => { },
  label = "button",
  icon,
  variant = "",
  className = "",
  disabled,
  type,
  Icon,
  loading,
  style,
  ...rest
}) =>
{
  return (
    <NewButtonContainer
      onClick={onClick}
      type={type}
      style={style}
      disabled={loading}
      className={`${variant} ${className}`}
      {...rest}
    >
      {loading ? <LoadingOutlined style={{ position: "relative", bottom: "5px" }} /> : icon ? (<><img src={icon} alt='alt.' /> {label}</>) : (  Icon  ? <> <Icon /> { label }  </> : label)}
    </NewButtonContainer>
  );
};

export default NewButton;
