import { request } from "utils/axios";



export const adverts = async () => {
    try {
        const res = await request.get("adverts");
        return res?.data;
    } catch (err) {
        //console.log(err);
        throw err;
    }
};








