import {
  ORDER,
  GET_CART,
  GET_HOMES,
  GET_ADDRESS,
  ADD_ADDRESS,
  ADD_TO_CART,
  UPDATE_CART,
  PRODUCT_END,
  PRODUCTS_END,
  PRODUCTS_ERROR,
  PRODUCTS_START,
  GET_FAVOURITES,
  DELETE_ADDRESS,
  GET_CATEGORIES,
  REMOVE_FROM_CART,
  UPDATE_FAVOURITES,
  REMOVE_FAVOURITES,
  UNATHENTICATED_CART,
  CLEAR_CART,
  GET_ADVERTS,
  PRODUCT_LOAD,
  GET_COUPON,
} from "../actions.types";
import { order } from 'services/order';
import { toast } from 'react-hot-toast';
import { addCart, getCarts, deletCart, updateCartItem, clearCart, deleteFav } from 'services/carts';
import { products, product, getHomes, getAddress, deleteAddress, getCategories as categories } from "services/business";
import { addCart as addUnauthenticatedCart, getCart } from "utils/helpers";
import { adverts } from "services/advert";


export const getCategories = () => async dispatch => {
  try {
    dispatch({ type: PRODUCTS_START });
    const res = await categories();
    dispatch({ type: GET_CATEGORIES, payload: res?.data });
  } catch (error) {
    dispatch({ type: PRODUCTS_ERROR });
    toast.error(
      error?.response?.data?.message ??
      "There was an error in fetching categories",
    );
    console.error(error);
  }
}

export const getProducts = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTS_START });
    const res = await products();
    dispatch({ type: PRODUCTS_END, payload: res?.data });
  } catch (error) {
    dispatch({ type: PRODUCTS_ERROR });
    toast.error(
      error?.response?.data?.message ??
      "There was an error in fetching products",
    );
    console.error(error);
  }
};

export const addresses = () => async (dispatch) => {
  try {
    const res = await getAddress();
    console.log(res)
    dispatch({ type: GET_ADDRESS, payload: res?.data });
  } catch (error) {
    toast.error(
      error?.response?.data?.message ??
      "There was an error in fetching C-way Homes",
    );
    console.error(error);
  }
};

export const addAddress = (value) => dispatch => {
  dispatch({
    type: ADD_ADDRESS,
    payload: value
  })
}

export const getAdverts = () => async (dispatch) => {
  try {
    const res = await adverts();

    dispatch({
      type: GET_ADVERTS,
      payload: res?.data
    })
  } catch (err) {
    console.log(err)
  }
}

export const removeAddress = (id) => async (dispatch) => {
  try {

    // dispatch({ type: PRODUCTS_START });
    const res = await deleteAddress(id);
    console.log(res)
    dispatch({ type: DELETE_ADDRESS, payload: id });
    toast.success(res?.message)
  } catch (error) {
    dispatch({ type: PRODUCTS_ERROR });
    toast.error(
      error?.response?.data?.message ??
      "There was an error in deleting address",
    );
    console.error(error);
  }
};

export const addCoupon = (value) => dispatch => {
  console.log(value, 'vall')
  dispatch({
    type: GET_COUPON,
    payload: value
  })
}

export const cwayHomes = () => async (dispatch) => {
  try {

    const res = await getHomes();
    console.log(res)
    dispatch({ type: GET_HOMES, payload: res?.data });
  } catch (error) {

    toast.error(
      error?.response?.data?.message ??
      "There was an error in fetching C-way Homes",
    );
    console.error(error);
  }
};

export const updateFavourite = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_FAVOURITES,
    payload: value
  })
}

export const getFavouites = (values) => async (dispatch) => {

  dispatch({
    type: PRODUCT_LOAD
  })

  dispatch({
    type: GET_FAVOURITES,
    payload: values
  })
}

export const removeFavourite = (value) => async (dispatch) => {
  try {
    console.log(value , 'valueee')
    const res = await deleteFav(value);
    console.log(res?.data , 'ress dataaaaa');
    toast.success(res?.message ?? 'Successfully deleted wishlist')
    dispatch({
      type: REMOVE_FAVOURITES,
      payload: value
    });
  } catch (err) {
    toast.error(
      err?.response?.data?.message ??
      "There was an error deleting wishlist",
    );
  }

}



export const getProduct = (values) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCTS_START });
    const res = await product(values);
    dispatch({ type: PRODUCT_END, payload: res?.data });
  } catch (error) {
    dispatch({ type: PRODUCTS_ERROR });
    toast.error(
      error?.response?.data?.message ??
      "There was an error in fetching product",
    );
    console.error(error);
  }
};

export const updateCart = async (value) => async (dispatch) => {
  try {
    const response = await updateCartItem(value.uuid, {
      quantity: value.value
    });
    dispatch({
      type: UPDATE_CART,
      payload: value
    })
  } catch (err) {
    console.log('error dey ')
  }
}


export const updateAuthenticatedCart = async (value) => async (dispatch) => {
  try {

    dispatch({
      type: UPDATE_CART,
      payload: value
    })
  } catch (err) {
    console.log('error dey ')
  }
}

export const addToCart = async (value) => async (dispatch) => {
  try {
    const res = await addCart(value);
    dispatch({
      type: ADD_TO_CART,
      payload: { ...res?.data, ...value }
    });

    toast.success('Item added added to cart')
  } catch (err) {
    console.log(err)
    toast.error(err?.response?.data?.message ?? 'There was an error in adding item')
  }
}

export const unAuthenticatedUserCart = (value, qnty) => async (dispatch) => {
  const newProd = {
    product: {
      uuid: value?.uuid,
      ...value
    },
    quantity: qnty
  }
  console.log(newProd);
  dispatch({
    type: UNATHENTICATED_CART,
    payload: newProd
  });
  addUnauthenticatedCart(newProd);
  toast.success('Item added added to cart')
}

export const removeItem = async (value) => async (dispatch) => {
  try {
    const response = await deletCart(value.uuid);
    console.log(response)
    dispatch({
      type: REMOVE_FROM_CART,
      payload: value
    })
  } catch (err) {
    console.log('erro dey ')
  }
}

export const removeItemUnAuthenticated = async (value) => async (dispatch) => {
  try {

    const cacheCart = getCart();
    const removed = cacheCart.filter(({ product }) => product?.uuid !== value.product.uuid)
    localStorage.setItem('cway:cart', JSON.stringify(removed));

    dispatch({
      type: REMOVE_FROM_CART,
      payload: value
    })
  } catch (err) {
    console.log(err)
    console.log('erro dey ')
  }
}

export const getCartItem = async (value) => async (dispatch) => {
  console.log(value , 'get cart..')
  if (value !== null) {
    dispatch({
      type: GET_CART,
      payload: value !== null ? value : []
    })
  } else {
    dispatch({ type: PRODUCTS_ERROR });
  }

}

export const orderProduct = async (value, id) => async (dispatch) => {
  try {
    console.log(id)
    const responseData = await order(value);
    const res = await clearCart(id);

    dispatch({
      type: ORDER,
      payload: responseData?.data
    });
    toast.success(responseData?.message)
    window.open('/orders', '_self');
  } catch (err) {
    toast.error(err?.response?.data?.message ?? 'there was an error in making order')

  }
}

export const clearCartItem = async (id) => async (dispatch) => {

  try {

    const res = await clearCart(id);
    dispatch({
      type: CLEAR_CART
    })
    return toast.success(res?.data?.message ?? 'Successfully cleared cart')
  } catch (err) {
    toast.error(err?.response?.data?.message)
  }
}

export const clearUnAuthenticatedCartItem = async () => async (dispatch) => {

  try {
    dispatch({
      type: CLEAR_CART
    })
    return toast.success('Successfully cleared cart')
  } catch (err) {
    toast.error('There was an error clearing cart')
  }
}



// category:
// created_at: "2022-06-29T13:23:17.000000Z"
// description: null
// discount: "0.00000000"
// id: 1
// image_url: null
// name: "hot"
// updated_at: "2022-06-29T13:23:17.000000Z"
// uuid: "3176f885-2cfc-46a1-99db-b6c74a8d4d2e"
// [[Prototype]]: Object
// category_id: "1"
// created_at: "2022-06-30T13:30:59.000000Z"
// description: "Awesone"
// discounted_percentage: "0"
// discounted_price: "0"
// id: 5
// is_out: "0"
// medias: Array(2)
// 0:
// created_at: "2022-06-30T13:30:59.000000Z"
// filename: "https://res.cloudinary.com/taoltech/image/upload/v1658934600/cway_product2022-04-19image8_prev_ui-min_1_twpitq.png"
// id: 3
// platform: "web"
// product_id: "5"
// type: "main"
// updated_at: "2022-06-30T13:30:59.000000Z"
// uuid: "0830de16-b090-4318-98e4-59e7c59ad19e"
// [[Prototype]]: Object
// 1: {id: 4, uuid: 'e9bfc192-e690-4046-a737-bc4ce25bcf85', product_id: '5', type: 'normal', filename: 'https://res.cloudinary.com/taoltech/image/upload/v…roduct2022-04-19image1_min-compress1_1_sje2x4.png', …}
// length: 2
// [[Prototype]]: Array(0)
// name: "Viju milk"
// price: "100.00000000"
// rating: "0"
// short_description: "Like very Awesone"
// updated_at: "2022-06-30T13:30:59.000000Z"
// uuid: "d02f0170-339d-401c-89eb-5e541fae9dcd"
// weight: "0.00000000"
