import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth, useActivity, useWallet } from 'hooks';
import { setLocationHistory, getToken, getLocationHistory } from 'utils/helpers';
import { getCarts } from 'services/carts';
import { getCart } from 'utils/helpers';
import { getIsAuth } from "utils/helpers";

const ProtectedRoutes = ({ Child }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const { stateAuth, userProfile } = useAuth();
  const { getCwayHomes,
    getAddress, fetchCart } = useActivity();
  const { fetchWallet } = useWallet()

  const getP = async () => {
    console.log('calling profile')
    userProfile();
    getCwayHomes();
    getAddress();
    fetchWallet();
    console.log('called');
    if (getLocationHistory() !== null) {
      console.log(getLocationHistory());
      navigate(getLocationHistory(), { replace: false })
      // window.open(getLocationHistory() , '_self');
      sessionStorage.removeItem("cway:user:redirect:location");
    }
  }

  const getUserCart = async () => {
    try {
      const responseData = await getCarts();
      return responseData?.data
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {

    if (getToken() !== null) {
      console.log(stateAuth.isAuthenticated)
      fetchCart(getUserCart())
      getP();

    }
    // console.log('hello')

  }, [location.pathname]);

  useEffect(() => {
    if (!getIsAuth()) {
      setLocationHistory(location.pathname);
      fetchCart(getCart())
      console.log(stateAuth.isAuthenticated, 'auth err')
      navigate('/', { replace: true })
    }
  }, [stateAuth.isAuthenticated, getIsAuth()])


  return <Child />;

}


export default ProtectedRoutes;


