import styled from 'styled-components';

export const NewButtonContainer = styled.button`
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  text-transform: capitalize;
  padding: 0.65rem 0.8rem;
  transition: all 0.4s;
  border: none;
  cursor: pointer;
  background: #5d84c1;

  // @media(max-width:768px){
  //   margin-top:16px;
  // }
`;