import React, { useEffect } from 'react';
import './mainFooter.css';
import { useAuth } from 'hooks';
import { useNavigate } from 'react-router-dom';
import call from '../../assets/icons/call_icon.svg';
import location from '../../assets/icons/locator.svg';
import email from '../../assets/icons/send_icon.svg';
import payment from '../../assets/images/payment.svg';
import whatsapp from '../../assets/icons/whatsapp.svg';
import cwaylogo from '../../assets/images/cwaylogo.png';
import appstore from '../../assets/images/appstore.svg';
import googleplay from '../../assets/images/playstore.svg';



const MainFooter = () =>
{
  // hooks
  const navigate = useNavigate();
  const { stateAuth } = useAuth();

  // styles
  const styles = ({
    span: { color: '#253d4e' },
    list: { color: '#253d4e', cursor: 'pointer' },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="cway-footer my-5 mx-lg-5 mx-4 d-none d-lg-flex">
      <section className="row my-5">
        <div className="mb-3 col-lg-3">
          <div className="logo" onClick={() => navigate('/')}>
            <img src={cwaylogo} alt="logo" />
          </div>
          <div className="d-flex mt-4">
            <img src={location} alt="location" />
            <article className="ps-2" style={styles.list}>
              <span>Address:</span> Plot 98/100, Oshodi – Apapa <br /> expressway,
              Isolo, Lagos, Nigeria
            </article>
          </div>
          <div className="d-flex my-3">
            <img src={call} alt="call" />
            <article className="ps-2" style={styles.list}>
              <span>Call Us:</span> <a style={styles.span} href="tel:(+234)-903 3031 123">(+234)-903 3031 123</a>
            </article>
          </div>
          <div className="d-flex">
            <img src={call} alt="call" />
            <article className="ps-2" style={styles.list}>
              <span>Call Us:</span> <a style={styles.span} href="tel:(+234)-901 7279 070">(+234)-901 7279 070</a>
            </article>
          </div>
          <div className="d-flex my-3">
            <img src={whatsapp} alt="whatsapp" />
            <article className="ps-2" style={styles.list} onClick={() => { window.open("https://wa.me/2349033031123") }}>
              <span>WhatsApp:</span> (+234)-903 3031 123
            </article>
          </div>
          <div className="d-flex">
            <img src={email} alt="whatsapp" />
            <article className="ps-2" style={styles.list}>
              <span>Email:</span> <a style={styles.span} href="mailto:cwayhome2021@gmail.com">cwayhome2021@gmail.com</a>
            </article>
          </div>
        </div>

        <div className='mb-3 col-lg-2 col-sm-6'>
          <div className='mb-4'>
            <h6>Company</h6>
          </div>
          <ul>
            <li style={styles.list} onClick={() => { window.open("https://pages.flycricket.io/cway-home/terms.html") }}>Privacy Policy</li>
            <li style={styles.list} onClick={() => { window.open("https://pages.flycricket.io/cway-home/terms.html") }}>Terms & Conditions</li>
          </ul>
        </div>

        <div className='mb-3 col-lg-2 col-sm-6'>
          <div className='mb-4'>
            <h6>Account</h6>
          </div>
          <ul>
            {/* <li style={styles.list} onClick={() => navigate("/login")}> Sign In</li> */}
            <li style={styles.list} onClick={() => navigate("/cart")}> View Cart</li>
            {/* <li style={{cursor: "pointer"}}>Track My Order</li> */}
            <li style={styles.list} onClick={() => { stateAuth?.isAuthenticated ? navigate("/orders") : navigate('/login')}}>View All Order</li>
            <li style={styles.list} onClick={() => { stateAuth?.isAuthenticated ? navigate("/favorites") : navigate('/login') }}>Favorites</li>
          </ul>
        </div>

        <div className='mb-3 col-lg-2'>
          <div className='mb-4'>
            <h6>Information</h6>
          </div>
          <ul>
            <li style={styles.list} onClick={() => navigate("/faq")}>Help & FAQ's</li>
            <li style={styles.list} onClick={() => { window.open("https://doc-hosting.flycricket.io/our-locations/7af67e9c-4652-41cf-bb7e-6e224d01fd4a/other") }}>Our Locations</li>
          </ul>
        </div>

        <div className='mb-3 col-lg-3'>
          <div className='mb-4'>
            <h6>App & Payment</h6>
          </div>
          <ul>
            <li style={styles.list} onClick={() => { stateAuth?.isAuthenticated ? navigate("/wallet") : navigate('/login') }}>Wallet</li>
          </ul>
          <p>Install CWAY Home App from App Store or Google Play</p>

          <div className='d-flex' style={{ columnGap: 20 }}>
            <div>
              <img style={styles.list} onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.cway.cway") }} src={googleplay} alt="googleplay" />
            </div>
            <div>
              <img style={styles.list} onClick={() => { window.open("https://apps.apple.com/us/app/cway-home/id1612427858?uo=4") }} src={appstore} alt="appstore" />
            </div>
          </div>

          <div className='my-3'>
            <p>Secured Payment Gateway</p>
          </div>
          <div className='mb-3'>
            <img src={payment} alt="payment method" />
          </div>
        </div>

        <div className='copyright py-4'>
          <p>&copy; 2023 CWAY Home Store - All rights reserved</p>
        </div>
      </section>
    </div>
  )
};

export default MainFooter;
