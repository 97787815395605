import Navbar from '../Navbar';
import { useState } from 'react';
import Carousel from "components/carousel";
import Badge from 'components/carousel/Badge';
import MainFooter from 'components/MainFooter';
import { MobileShow, MobileSection } from './styled';
import { sidebarRoutes } from 'constants/sidebar';
import { useActivity } from 'hooks/useBusiness.js';



const Layout = ({ children }) => {

  const { state } = useActivity()
  const advert = state?.adverts?.filter((data) => data?.medias[0].platform === 'web' && data?.medias[0].placement === 'bottom') ?? []
  return (
    <>
      <MobileSection>
        <Navbar />
        <MobileShow>{children}</MobileShow>
      </MobileSection>

      {advert?.length > 0 ? <Carousel position="bottom" /> : <Badge />}
      <MainFooter />
    </>
  )
};

const WithLayout = (Component) => {
  return (props) => {
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    )
  }
}

export default WithLayout;
