// import styled from "styled-components";

// // the carousel section
// export const Section = styled.section`
//   width: 100%;
//   display: flex;
//   padding: 0rem 4%;
//   margin: 1rem 0rem;
//   position: relative;
//   flex-direction: column;
//   height: clamp(20rem, calc(100vw / 3), 30rem);

//   @media screen and (max-width: 25rem) {
//     & {
//       width: calc(100vw - 1rem);
//     }
//   }
// `;

// // the image container
// export const ImgCon = styled.div`
//   width: 100%;
//   display: flex;
//   flex: 8 1 auto;
//   overflow: hidden;
//   aspect-ratio: 16/9;
//   position: relative;
//   background-color: grey;
//   border-radius: 0.5rem;
//   align-items: flex-start;
//   justify-content: flex-start;
// `;

// // the image element
// export const Img = styled.img`
//   flex: none;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   position: relative;
//   transition: 400ms ease;
//   transform: translateX(-${({ index }) => index * 100}%);
// `;

// // the arrow container
// export const ArrCon = styled.div`
//   inset: 0;
//   display: flex;
//   position: absolute;
//   align-items: center;
//   pointer-events: none;
//   justify-content: space-between;
// `;

// // the arrow element or button
// export const ArrBtn = styled.div`
//   width: 2rem;
//   height: 2rem;
//   display: flex;
//   cursor: pointer;
//   border-radius: 50%;
//   position: relative;
//   pointer-events: all;
//   align-items: center;
//   margin-inline: 0.5rem;
//   justify-content: center;
//   transition: 200ms linear;
//   background-color: rgba(0 0 0 / 0.6);

//   &:hover {
//     background-color: rgba(0 0 0 / 0.8);
//   }

//   &:active {
//     transform: scale(0.9);
//   }

//   &::before {
//     width: 20%;
//     height: 20%;
//     content: "";
//     float: none;
//     position: absolute;
//     border-top: 0.2rem solid white;
//     border-right: 0.2rem solid white;
//     transform: rotate(${({ dir }) => (dir === "left" ? -135 : 45)}deg)
//       translateX(-20%);
//   }
// `;

// // the progress container
// export const ProgCon = styled.div`
//   width: 100%;
//   display: flex;
//   flex: 1 1 4rem;
//   align-items: center;
//   justify-content: center;
// `;

// // the progress element
// export const ProgTab = styled.span`
//   height: 0.5rem;
//   cursor: pointer;
//   border-radius: 4rem;
//   margin: auto 0.3rem;
//   width: ${(props) => (props.current ? "1.6rem" : "0.5rem")};
//   transition: 200ms ${({ current }) => (current ? 0 : 50)}ms linear;
//   background-color: #${({ current: cur }) => (cur ? "0893ca" : "00000066")};
// `;

import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0% 4%;
  margin: 1rem 0rem;
  position: relative;
  flex-direction: column;
  height: clamp(33.5rem, calc(100vw / 3), 33.5rem);

  @media (max-width: 640px) {
    height: clamp(14.5rem, calc(100vw / 1), 14.5rem);
  }
`

export const ReferWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0rem 4.2%;
  margin: 1rem 0rem;
  position: relative;
  flex-direction: column;
  font-family: 'Quicksand';
  color: #1c4037;
  height: clamp(20rem, calc(100vw / 2), 20rem);

  &#badge {
    @media screen and (max-width: 50rem) {
      display: none;
    }
  }

  @media screen and (max-width: 25rem) {
    width: calc(100vw - 1rem);
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 8 1 auto;
  overflow: hidden;
  aspect-ratio: 5/4;
  position: relative;
  background-color: grey;
  border-radius: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
`

export const Img = styled.img`
  flex: none;
  width: 100%;
  height: clamp(32rem, calc(100vw / 3), 32rem);
  object-fit: cover;
  position: relative;
  transition: 400ms ease;
  transform: translateX(-${({ index }) => index * 100}%);

  @media (max-width: 640px) {
    height: clamp(12.5rem, calc(100vw / 1), 12.5rem);
  }
`

export const Arrow = styled.div`
  inset: 0;
  display: flex;
  position: absolute;
  align-items: center;
  pointer-events: none;
  justify-content: space-between;
`

export const Btn = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  pointer-events: all;
  align-items: center;
  margin-inline: 0.5rem;
  justify-content: center;
  transition: 200ms linear;
  background-color: rgba(0 0 0 / 0.6);

  &:hover {
    background-color: rgba(0 0 0 / 0.8);
  }

  &:active {
    transform: scale(0.9);
  }

  &::before {
    width: 20%;
    height: 20%;
    content: '';
    float: none;
    position: absolute;
    border-top: 0.2rem solid white;
    border-right: 0.2rem solid white;
    transform: rotate(${({ dir }) => (dir === 'left' ? -135 : 45)}deg)
      translateX(-20%);
  }
`

export const Progress = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 4rem;
  align-items: center;
  justify-content: center;
`

export const Tab = styled.span`
  height: 0.5rem;
  cursor: pointer;
  border-radius: 4rem;
  margin: auto 0.3rem;
  width: ${(props) => (props.current ? '1.6rem' : '0.5rem')};
  transition: 200ms ${({ current }) => (current ? 0 : 50)}ms linear;
  background-color: #${({ current: cur }) => (cur ? '0893ca' : '00000066')};
`

export const Badger = styled(Container)`
  flex: none;
  height: 22em;
  flex-wrap: wrap;
  display: flex;
  padding: 0 1rem;
  aspect-ratio: none;
  align-items: center;
  justify-content: center;
  background-color: #e0ebea;

  img {
    width: 50%;
    height: 80%;
    object-fit: contain;
  }

  .detail {
    display: flex;
    flex: 1 1 20em;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    h2 {
      color: #1c4037;
      font-size: 2.5em;
      font-weight: 700;

      span {
        color: red;
      }
    }

    button {
      color: white;
      margin: 1em 0;
      font-weight: 900;
      padding: 0.5em 2em;
      border-radius: 2em;
      background-color: #5d84c1;
      text-transform: capitalize;
      border: 0.2em solid #2e405c;
    }
  }
`
