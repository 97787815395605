import toast from 'react-hot-toast';
import { myWallet, Transactions, getTransactions as allTrans } from 'services/wallet'

import { WALLET_END, WALLET_ERROR, WALLET_START, WALLET_TRANSACTIONS } from '../../actions/actions.types';


export const getWallet = () => async dispatch => {
    try {
        dispatch({ type: WALLET_START });
        const res = await myWallet();
        dispatch({ type: WALLET_END, payload: res?.data });
    } catch (error) {
        dispatch({ type: WALLET_ERROR });
        toast.error(error?.response?.data?.message ?? "There was an error in getting your wallet details")
        console.error(error);
    }

}

export const getTransactions = async (id, page) => async dispatch => {
    try {
        console.log(id)
        const res = await allTrans(id, page);
        console.log(res)
        dispatch({ type: WALLET_TRANSACTIONS, payload: res })
    } catch (error) {
        dispatch({ type: WALLET_ERROR });
        toast.error(error?.response?.data?.message ?? "There was an error in getting your wallet details")
        console.error(error);
    }
}





// :
// "1080.00"
// created_at
// :
// "2022-11-15T15:19:25.000000Z"
// description
// :
// "Product purchase"
// id
// :
// 50
// transaction_ref
// :
// "tref_duPLuGf2za"
// type
// :
// "DEBIT"
// updated_at
// :
// "2022-11-15T15:19:25.000000Z"
// uuid
// :
// "c3bb2933-14ab-4c66-b56d-06599b7094cf"
// wallet_id
// :
// "14"