import styled from "styled-components";

export const StyledContainer = styled.section`
  gap: 2rem;
  width: 80%;
  margin: auto;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr 9fr;

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Content = styled.div`
  overflow: auto;

  h2 {
    color: #253d4e;
    font-size: 2rem;
    font-family: var(--font-family-quicksand);
    letter-spacing: -0.0004em;
  }
`;

export const MobileShow = styled.div`

@media (max-width: 768px) {

  .layoutChild {
  height: calc(100vh - 60px);
  width: 400px;
  overflow-y: auto;
  background-color: #ffaaaa;
}

  .sm-side {
    position: absolute;
    
    z-index: 200;
    left: 0;
    
    transition: all 0.5s;
  }

  .sm-side-close {
    position: absolute;
    width: 100%;
    max-width: 400px;
    z-index: 200;
    left: -400px;
    transition: all 0.5s;
  }
}

`

export const MobileSection = styled.section`
.layout-main {
  display: grid;
  grid-template-columns: minmax(200px, 278px) 6fr;
}
@media (max-width: 768px) {
  .layout-main {
    display: block;
    position: relative;
  }
}
`
export const SidebarCon = styled.div`
  z-index: 10;
  background-color: white;

  

  @media screen and (max-width: 40em) {
    width: 0%;
    padding: 1em 0;
    overflow: hidden;
    position: absolute;
    transition: 200ms ease-out;

    &.open {
      width: 100%;
      padding: 1em;
    }
  }
`;

export const Btn = styled.b`
  @media screen and (min-width: 40rem) {
    display: none;
  }
  
  width: 2.5em;
  height: 1.5em;
  display: flex;
  position: relative;
  margin: 1em 0 0 1em;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  i {
    flex: none;
    width: 100%;
    height: 20%;
    transition: 200ms;
    border-radius: 1em;
    background-color: blue;
  }

  &.open i {
    width: 70%;
    height: 25%;
    position: absolute;
    transform: rotate(45deg);

    &:last-child {
      transform: rotate(-45deg);
    }
  }
`;
