import styled from "styled-components";

export const StyledSettingSideNav = styled.div`
  z-index: 10;
  padding: 1em;
  background: white;

  ul {
    align-items: flex-start;
    flex-direction: column;

    a {
        width: 90%;
    }

    li {
      border: 1px solid #e5e5e5;
      padding: 0.5rem 2rem;
      font-size: 0.8em;
      font-weight: 600;
      color: #7e7e7e;
      min-width: 250px;
      width: 100%;
      border-radius: 10px;
      transition: all 0.5s;
      display: flex;
      align-items: center;
      gap: 1rem;
      letter-spacing: 0.8px;

      cursor: pointer;
    }

    li:hover {
      background: #5d84c1;
      color: #ffff;
      border: 1px solid #5d84c1;
    }
    li.btnActive {
      background: #5d84c1;
      color: #ffff;
      border: 1px solid #5d84c1;
    }
    @media (max-width: 768px) {
      .layout-main {
        display: none;
      }
    }
  }
`;
export const Ul = styled.ul`
  list-style: none;
  display: flex;
  gap: 1rem;
`;
