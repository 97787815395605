/* eslint-disable no-unused-vars */
import {
  Btn,
  Logo,
  NavLink,
  NavbarCon,
  SearchMenu,
  HeaderMenu,
  ContactLink,
  UserDropDown,
  HamburgerMenu,
  HeaderContainer,
  SearchContainer,
  NavbarContainer,
} from "./NavbarStyles";
import "./header.css";
import "antd/dist/antd.min.css";
import NewButton from "../newButton";
import { Divider, Input } from "antd";
import water from "assets/icons/water.svg";
// import { getFavorite } from 'utils/helpers';
import { useAuth, useActivity } from "hooks";
import logo from "assets/images/cwaylogo.png";
import { BiCategoryAlt } from "react-icons/bi";
import { navBarRoutes } from "constants/navbar";
import callIcon from "assets/icons/nav_call.svg";
import cartIcon from "assets/icons/cart-icon.svg";
import userIcon from "assets/icons/user-icon.svg";
import angleDown from "assets/icons/angleDown.svg";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import favIcon from "assets/icons/favorite-icon.svg";
import { useNavigate, Link } from "react-router-dom";
import { getFav } from 'services/carts';
import MenuOutlined from "@ant-design/icons/MenuOutlined";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { LoadingIcon } from 'components/Loading'
import debounce from 'lodash.debounce';
import { searchProduct } from "services/business";


const Navbar = ({ routes = [] }) => {
  // Hooks
  const navigate = useNavigate();
  const { stateAuth, logUserOut, userProfile } = useAuth();
  const { state, getCategories, fetchFavourites, adds } = useActivity();

  // Favorite state hook
  const [open, setOpen] = useState(0);
  const [search, setSearch] = useState('')
  const [searchRes, setSearchRes] = useState(null);
  const [loading, setLoading] = useState(false); // loading state hook
  const [mobile, setIsMobile] = useState(false); // Mobile Nav Function
  const [category, setCategory] = useState(null); // Category state hook
  const [links, setLinks] = useState(navBarRoutes); // the default navbar links
  // const [favorite] = useState(getFavorite()?.length ?? 0); // Favorite state hook
  const [userNav, setUserNav] = useState(null); // this toggles the user dropdown menu (only if user is logged in)

  const getFavs = async () => {
    try {

      const res = await getFav();
      if (res?.status) {
        fetchFavourites(res?.data);
        return;
      }
    } catch (err) {

      console.log(err?.response?.data)
    }
  }


  const handleChange = async (e) => {
    try {
      if (e.target.value !== '') {
        setSearch(e.target.value)
        setLoading(true)
        const res = await searchProduct(e.target.value);
        setSearchRes(res?.data)
        setLoading(false)
        console.log(res)
      } else {
        setSearchRes(null)
      }

    } catch (err) {
      setLoading(false)
      console.log(err?.response?.data)
    }

  }

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300);
  }, []);


  // Use effects
  useEffect(() => {
    getCategories();
    adds();
    userProfile();
    if (stateAuth.isAuthenticated) {
      getFavs()
    }
    return () => {
      debouncedResults.cancel();
    };
  }, [stateAuth.isAuthenticated]); // fetches the categories
  useEffect(() => (window.onscroll = () => mobile && setIsMobile(false)), []); // listens to window scrolling event
  // adds the first five categories to the navlinks

  // Methods
  const handleClick = () => {
    // setLoading(true);
    // setTimeout(() => setLoading(false), 2000);
  };

  const capitalizeFirstLetter = (str) => {
    // convert first letter to uppecase
    const capitalized = str.replace(/^./, str[0].toUpperCase());
    return capitalized;
  }


  // Attributes
  const navLinkClassName = "navside";
  const navLinkIconClassName = "d-md-none mr-2 d-lg-none";
  const inputStyles = {
    border: "2px solid #6d8dbf",
    borderRadius: "5px",
    fontSize: "0.75rem",
  };

  const userInfo = [
    {
      name: 'ACCOUNT INFO',
      path: '/account-info'
    },
    {
      name: 'ORDERS',
      path: '/orders'
    },
    {
      name: 'WALLET',
      path: '/wallet'
    },
    {
      name: 'ADDRESS',
      path: '/address'
    },
    {
      name: 'DISPENSER REPAIR',
      path: '/dispenser-repair'
    },
    {
      name: 'VIP',
      path: '/VIP'
    }
  ]

  console.log(state.allCategories)

  return (
    <>
      <HeaderContainer className="mt-3 position-sticky" onMouseDown={() => setSearchRes(null)} >
        <HamburgerMenu onClick={() => setOpen(!open)}>
          {open ? <CloseOutlined size={10} /> : <MenuOutlined />}
        </HamburgerMenu>

        <Logo onClick={() => navigate("/")}>
          <img src={logo} alt="logo" />
        </Logo>

        <SearchMenu className="me-lg-2">
          <SearchContainer>
            <Input
              suffix
              allowClear
              className="py-2"
              onChange={handleChange}
              style={inputStyles}
              placeholder="Search products..."
            />

          </SearchContainer>

          <NewButton
            label="SEARCH"
            loading={loading}
            className="mx-2"
            onClick={handleClick}
          />
        </SearchMenu>

        <HeaderMenu>
          <span
            className="favorite span ms-lg-5"
            onClick={() => { !stateAuth.isAuthenticated ? navigate('/login', { replace: true }) : navigate("/favorites", { replace: true }) }
            }
          >
            <img src={favIcon} alt="Favorite" />
            <i className="ps-1">Favorites</i>
            {stateAuth?.isAuthenticated && state.favourites.length > 0 ? (
              <b>{state.favourites.length}</b>
            ) : null}
          </span>
          <span className="span">
            <div>
              <img onClick={() => { stateAuth?.isAuthenticated ? setUserNav(!userNav) : navigate("/login") }} src={userIcon} alt="User" />
              <UserDropDown show={userNav}>
                <Link to="/account-info">account info</Link>
                <Link to="/orders">orders</Link>
                <Link to="/wallet">wallet</Link>
                <Link to="/dispenser-repair">dispenser repair</Link>
                <Link to="#" onClick={() => logUserOut()}>
                  log out
                </Link>
              </UserDropDown>
            </div>

            {stateAuth?.user?.name ? (
              <i onClick={() => setUserNav(!userNav)}>
                {capitalizeFirstLetter(stateAuth?.user?.name)}
                <img
                  className="ms-1"
                  src={angleDown}
                  style={{ width: '15px' }}
                  alt="dropdown"
                />
                <UserDropDown show={userNav}>
                  <Link to="/account-info">account info</Link>
                  <Link to="/orders">orders</Link>
                  <Link to="/wallet">wallet</Link>
                  <Link to="/dispenser-repair">dispenser repair</Link>
                  <Link to="#" onClick={() => logUserOut()}>
                    log out
                  </Link>
                </UserDropDown>
              </i>
            ) : (
              <>
                {stateAuth?.loading ? (
                  <span>Profile loading.....</span>
                ) : (
                  <>
                    <i className="ps-1" onClick={() => navigate("/login")}>
                      Login /
                    </i>
                    <i className="ps-1" onClick={() => navigate("/register")}>
                      Signup
                    </i>
                  </>
                )}
              </>
            )}
          </span>
          <span className="span" onClick={() => navigate("/cart")}>
            <img src={cartIcon} alt="Cart" />
            <i className="ps-1">Cart</i>{" "}
            {state.cart?.length > 0 ? <b>{state.cart?.map((data) => +data.quantity).reduce((a, b) => a + b)}</b> : null}
          </span>
        </HeaderMenu>
      </HeaderContainer>
      <Divider className="my-0" />

      <NavbarContainer>
        <Btn
          className={category ? "open" : ""}
          onClick={() => setCategory(!category)}
        >
          <BiCategoryAlt />
          browse all categories
          {category ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          <div className="modal">

            {state.allCategories.length > 0 && state.allCategories?.map(
              (
                item,
                id // other links from props
              ) => (
                <NavLink key={id} to={`/category/${item?.name}/${item?.uuid}`} className={navLinkClassName}>
                  <img style={{ width: "20px" }} src={item?.image_url} alt="" />{item?.name}
                </NavLink>
              )
            )}
          </div>
        </Btn>

        <NavbarCon open={open}>

          <NavLink to={`/`} className={navLinkClassName}>
            HOME
          </NavLink>
          <NavLink to={`/store`} className={navLinkClassName}>
            STORE
          </NavLink>
          {state.allCategories.length > 0 && state.allCategories?.filter((data) => data?.visible === 1)?.map(
            (
              item,
              id // other links from props
            ) => (
              <NavLink key={id} to={`/category/${item?.name}/${item?.uuid}`} className={navLinkClassName}>
                {item?.name}
              </NavLink>
            )
          )}

          {
            stateAuth.isAuthenticated ? userInfo.map((data, i) => (
              <NavLink key={i} to={data.path} className={'d-lg-none d-md-none navside'}>
                {data.name}
              </NavLink>
            )) : null
          }

        </NavbarCon>

        <ContactLink>
          <div className="info">
            <h3><img style={{ width: '25px' }} src={callIcon} alt="call" /><a style={{ color: "#5d84c1" }} href="tel:(+234)-903 3031 123">(+234)-903 3031 123</a></h3>
            <span>Monday - Friday 8am - 5pm</span>
            <span>Saturdays 9am - 4pm</span>
          </div>
        </ContactLink>
      </NavbarContainer>

      <Divider className="my-0" />
      {searchRes !== null && searchRes?.length > 0 ? <div
        style={{
          zIndex: '99',
          width: '44.5%',
          border: '1px solid #efefef',
          background: '#fff',
          borderRadius: '7px',
          padding: '30px 0px',
          position: 'absolute',
          top: '90px',
          left: '340px'
        }}
      >
        {
          searchRes?.map((data, i) => (
            <div className="d-flex justify-content-between search-api" key={i}
              style={{ cursor: 'pointer', padding: '0px 50px' }}
              onClick={() => { navigate(`/product/${data?.category?.name}/${data?.uuid}`, { replace: true }); setSearchRes(null) }} >
              <div>
                <img className="my-2" src={data?.medias[0].filename} alt={'img'} style={{ width: '40px', height: '40px' }} />
              </div>
              <p className="py-2"> {data?.name} </p>
              <p className="py-2"> {data?.price} </p>
            </div>
          ))
        }
      </div> : null}
    </>
  );
};

export default Navbar;
