import { request } from "utils/axios";


export const myWallet = async () => {
    try {
        const res = await request.get('wallets')
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'You have no money');
        throw error;
    }
}


export const topUp = async (values) => {
    try {
        const res = await request.post('wallets', values)
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'You have no money');
        throw error;
    }
}


export const Transactions = async () => {
    try {
        const res = await request.get('wallets');
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'No transactions');
        throw error;
    }
}



export const getTransactions = async (id, page) => {
    try {
        const res = await request.get(`wallets/${id}/transactions?page=${page}`);
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'No transactions');
        throw error;
    }
}

