import { request } from "utils/axios";


export const order = async (value) => {
    try {
        const res = await request.post('orders', value);
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
};

export const orders = async (page = 1) => {
    try {
        const res = await request.get(`orders?page=${page}`);
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
};

export const getOrder = async (id) => {
    try {
        const res = await request.get(`orders/${id}`);
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
}

export const addDeliveryNote = async (value, id) => {
    try {
        const res = await request.put(`orders/${id}/notes`, value);
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
}

export const rating = async (value) => {
    try {
        const res = await request.post(`ratings`, value);
        console.log(res?.data)
        return res?.data
    } catch (error) {
        console.log(error?.response?.data?.message, 'somethings went wrong');
        throw error;
    }
}






