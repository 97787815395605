import { StyledSettingSideNav, Ul } from "./sidebar.styled";
import { sidebarRoutes } from "constants/sidebar";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAuth } from "hooks";


export const SideBar = () => {
  const { pathname } = useLocation();
  const { logUserOut } = useAuth()
  const activateLink = (path) => (pathname.includes(path) ? "btnActive" : "");

  return (
    <div className="d-md-flex d-lg-flex d-none">
      <StyledSettingSideNav>
        <Ul>
          {sidebarRoutes.map((item, id) =>
            item?.name.includes("Logout") ? (
              <li key={id} onClick={logUserOut} >
                <img src={item?.icon} alt="logout" /> {item?.name}
              </li>
            ) : (
              <Link to={item?.path} key={id}>
                <li className={activateLink(item?.path)}>
                  <img src={item?.icon} alt={item?.icon} /> {item?.name}
                </li>
              </Link>
            ),
          )}
        </Ul>
      </StyledSettingSideNav>
    </div>
  );
};
