import { Link } from "react-router-dom";
import { CheckoutHeader } from "./NavbarStyles";
import arrow from '../../assets/icons/right-arrow.svg'

const Header = ({ link }) => (
  <CheckoutHeader>
    <Link to={'/'}>home</Link>
    <img className="px-3" src={arrow} alt="" />
    {link && <Link to={link?.to}>{link?.title.replace('-', ' ')}</Link>}
  </CheckoutHeader>
);


export default Header;
