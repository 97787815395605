export const INIT_STATE_BUSINESS = {
  cart: [],
  total: 0,
  homes: [],
  address: [],
  product: {},
  productLoading: false,
  business: [],
  products: [],
  deliveryFee: 0,
  couponAmount: {
    price: 0
  },
  adverts: [],
  cartId: null,
  favourites: [],
  categories: [],
  allCategories: [],
  loading: false,
};

export const INIT_STATE = {
  user: {},
  resend: true,
  loading: false,
  greet: "HELLO AUTH",
  isAuthenticated: false,
};

export const INIT_STATE_WALLET = {
  wallet: {},
  loading: false,
  transactions: {},
};


