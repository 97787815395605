import {
  LOG_OUT,
  LOGIN_USER,
  AUTH_START,
  AUTH_ERROR,
  REGISTER_USER,
  RESET_PASSWORD,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  VERIFY_ACCOUNT_START,
  VERIFY_ACCOUNT_ERROR,
  VERIFY_ACCOUNT_SUCCESS,
  USER_OBJ_UPDATE
} from "../../actions/actions.types";
import { INIT_STATE } from "../../initialstates";
import { isAuth } from "utils/helpers";

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_START:
      return { ...state, loading: true, resend: false };

    case AUTH_ERROR:
      isAuth(false);
      return {
        ...state, loading: false,
        resend: true, isAuthenticated: false
      };

    case REGISTER_USER:
      return { ...state, loading: false };

    case VERIFY_ACCOUNT_START:
      return { ...state, loading: true };

    case VERIFY_ACCOUNT_SUCCESS:
      return { ...state, loading: false };

    case VERIFY_ACCOUNT_ERROR:
      return { ...state, loading: false };

    case RESET_PASSWORD:
      return { ...state, loading: false };

    case LOGIN_USER:
      isAuth(true);
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
      };

    case GET_USER_PROFILE:
      isAuth(true);
      return {
        ...state,
        loading: false,
        user: action.payload,
        isAuthenticated: true,
      };

    case UPDATE_USER_PROFILE:
      isAuth(true);
      return {
        ...state,
        loading: false,
        user: action.payload,
        isAuthenticated: true,
      };

      case USER_OBJ_UPDATE:
      return {
        ...state,
        userObj: { ...state?.userObj, ...action?.payload },
        loading: false,
      };

    case LOG_OUT:
      isAuth(false);
      return {
        ...state,
        isAuthenticated: false,
      };

    default:
      return state;
  }
};
export default authReducer;
// referral_code
