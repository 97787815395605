import logoutIcon from "assets/icons/logOut.svg";
import addressIcon from "assets/icons/location.svg";
import orderIcon from "assets/icons/setting.svg";
import walletIcon from "assets/icons/bag.svg";
import personIcon from "assets/icons/person.svg";

export const sidebarRoutes = [
    {
        name: 'Account Information',
        path: '/account-info',
        icon: personIcon
    },
    {
        name: 'Change Password',
        path: '/change-password',
        icon: personIcon
    },
    {
        name: 'Orders List',
        path: '/orders',
        icon: orderIcon
    },
    {
        name: 'Wallet',
        path: '/wallet',
        icon: walletIcon
    },
    {
        name: 'My Address',
        path: '/address',
        icon: addressIcon
    },
    {
        name: 'VIP',
        path: '/VIP',
        icon: personIcon
    },
    {
        name: 'Dispenser Repair',
        path: '/dispenser-repair',
        icon: orderIcon
    },
    {
        name: 'Referral Code',
        path: '/referral-code',
        icon: personIcon
    },
    {
        name: 'Logout',
        path: '',
        icon: logoutIcon
    },
]

