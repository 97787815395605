// auth action types
export const LOG_OUT = "LOG_OUT";
export const LOGIN_USER = "LOGIN_USER";
export const AUTH_START = "AUTH_START";
export const AUTH_ERROR = "AUTH_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESEND_OTP = "RESEND_OTP";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const VERIFY_ACCOUNT_START = "VERIFY_ACCOUNT_START";
export const VERIFY_ACCOUNT_ERROR = "VERIFY_ACCOUNT_ERROR";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const CLEAR_CART = 'CLEAR_CART';
export const USER_OBJ_UPDATE = "USER_OBJ_UPDATE";


// business action types
export const ORDER = 'ORDER';
export const GET_CART = 'GET_CART';
export const GET_HOMES = 'GET_HOMES';
export const PRODUCT_END = "PRODUCT_END";
export const ADD_TO_CART = 'ADD_TO_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const REDUCE_CART = 'REDUCE_CART';
export const GET_ADDRESS = 'GET_ADDRESS';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const PRODUCTS_END = "PRODUCTS_END";
export const INCREASE_CART = 'INCREASE_CART';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const GET_FAVOURITES = 'GET_FAVOURITES';
export const PRODUCTS_START = "PRODUCTS_START";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";
export const UPDATE_FAVOURITES = 'ADD_FAVOURITES';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const REMOVE_FAVOURITES = 'REMOVE_FAVOURITES';
export const UNATHENTICATED_CART = 'UNATHENTICATED_CART';

// wallet action types
export const WALLET_END = "WALLET_END";
export const WALLET_START = "WALLET_START";
export const WALLET_ERROR = "WALLET_ERROR";
export const WALLET_TRANSACTIONS = "WALLET_TRANSACTIONS";
export const GET_ADVERTS = 'GET_ADVERT';

export const PRODUCT_LOAD = 'PRODUCT_LOAD';
export const GET_COUPON = 'GET_COUPON';









