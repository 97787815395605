import {
  cwayHomes,
  addresses,
  addToCart,
  removeItem,
  updateCart,
  addAddress,
  getProduct,
  getCartItem,
  getProducts,
  orderProduct,
  getFavouites,
  removeAddress,
  unAuthenticatedUserCart,
  updateAuthenticatedCart,
  updateFavourite,
  getCategories as categories,
  removeItemUnAuthenticated as unAuthenciatedRemove,
  clearCartItem, clearUnAuthenticatedCartItem,
  getAdverts, removeFavourite, addCoupon 
} from "store/actions/business";
import { useSelector, useDispatch } from "react-redux";
import { saveFavorite, getFavorite, getCart } from "utils/helpers";
import { clearCart } from "utils/helpers";



export const useActivity = () => {
  // Store Hooks and Action Dispatch
  const dispatch = useDispatch();
  const state = useSelector(({ business }) => business);
  const stateAuth = useSelector((state) => state.auth);

  // Methods
  const fetchProducts = () => dispatch(getProducts());

  const fetchProduct = (uuid) => dispatch(getProduct(uuid));

  const addItem = (value) => {
    dispatch(addToCart(value))
    console.log(state.cart)
  };

  const fetchCart = (value) => {

    dispatch(getCartItem(value))
  };

  const fetchFavourites = (data) => {
    console.log(data)
    dispatch(getFavouites(data ?? []))
  }

  const addFav = (values) => {

    dispatch(
      updateFavourite(values)
    );

  };

  const getCwayHomes = () => dispatch(cwayHomes());
  const adds = () => dispatch(getAdverts());
  const getAddress = () => dispatch(addresses());

  const getCategories = () => dispatch(categories());
  const clear = (id) => {

    if (state.cart?.length > 0) {
      if (stateAuth.isAuthenticated) {
        dispatch(clearCartItem(id))
      } else {
        clearCart()
        dispatch(clearUnAuthenticatedCartItem())
      }
    };
  }
  const update = (value) => {
    if (stateAuth.isAuthenticated) {
      dispatch(updateCart(value))
    } else {
      const cacheCart = getCart();
      // const findCart = cacheCart.find((data) => data?.product?.uuid === value?.product?.uuid);
      cacheCart.map((val) => {
        if (val?.product?.uuid === value?.product?.uuid) {
          val.quantity = value?.value
        }
        return val;
      })

      localStorage.setItem('cway:cart', JSON.stringify(cacheCart));
      dispatch(updateAuthenticatedCart(value))
    }
  }

  const getCoupon = (value) => dispatch(addCoupon(value))

  const removeCart = (value) => {
    if (stateAuth.isAuthenticated) {
      dispatch(removeItem(value))
    } else {
      dispatch(unAuthenciatedRemove(value))
    }

  };

  const deleteAddress = (id) => dispatch(removeAddress(id));

  const removeFav = (uuid) => dispatch(removeFavourite(uuid));

  const makeOrder = (value, id) => dispatch(orderProduct(value, id));

  const pushAddress = (value) => dispatch(addAddress(value));

  const addUnauthenticatedCart = (value, qnty) => dispatch(unAuthenticatedUserCart(value, qnty));

  return {
    state,
    update,
    addFav,
    addItem,
    fetchCart,
    makeOrder,
    removeCart,
    getAddress,
    pushAddress,
    getCwayHomes,
    addUnauthenticatedCart,
    fetchProduct,
    fetchProducts,
    deleteAddress,
    getCategories,
    fetchFavourites,
    clear,
    adds,
    removeFav,
    getCoupon
  };
};
