import { request } from "utils/axios";

export const register = async (values) => {
  try {
    const res = await request.post("auth/register", values);
    return res?.data;
  } catch (err) {
    //console.log(err);
    throw err;
  }
};

export const userLogin = async (values) => {
  try {
    const res = await request.post("auth/login", values);
    return res?.data;
  } catch (err) {
    //  console.log(err);
    throw err;
  }
};

export const profile = async () => {
  try {
    const res = await request.get("users");
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const updateProfile = async (values) => {
  try {
    const res = await request.put("users", values);
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const forgotPassword = async (values) => {
  try {
    const res = await request.post("auth/password/phone", values);
    console.log(res?.data);
    return res?.data;
  } catch (err) {
    console.log(err?.response);
    throw err;
  }
};

export const resendEmail = async (value) => {
  try {
    const res = await request.post("resendEmail", value);
    console.log(res?.data);
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const verifyEmail = async (token) => {
  try {
    const res = await request.post("verifyEmail", { token });
    console.log(res.data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const resetPassword = async (value) => {
  try {
    const res = await request.post("auth/password/reset", value);
    console.log(res?.data);
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const verifyAccount = async (value) => {
  try {
    const res = await request.post("auth/verify/phone", value);
    console.log(res?.data);
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const ResendCode = async (value) => {
  try {
    const res = await request.post("auth/resend-code", value);
    console.log(res?.data);
    return res?.data;
  } catch (err) {
    throw err;
  }
};


export const changePassword = async (value) => {
  try {
    const res = await request.post("users/password", value);
    return res?.data;
  } catch (err) {
    throw err;
  }
};