import React from 'react';
import { Container, LoadingWrapper, Ball, WrapLoad } from './Loading.styled';
import logo from "assets/images/cwaylogosvg.svg";

const Loader = () => (
	<LoadingWrapper>
		<Ball />
		<Ball />
		<Ball /> 
		<Ball />
		<Ball />
		
	</LoadingWrapper>
);

export const LoadingIcon = ({ fullscreen }) => {
	return fullscreen ? (
		<>
		
		<Container>
		<img src={logo} alt={"logo"}/>
			<WrapLoad>
			<Loader />
			</WrapLoad>
		</Container>
		</>
	) : (
		<Loader />
	);
};
