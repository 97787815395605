import "./App.css";
import AppRouter from "routes/AppRouter";

function App() {

  if (process.env.NODE_ENV !== 'development') {
    console.log = function () { };
  }

  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
