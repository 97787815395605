import { INIT_STATE_WALLET } from "../../initialstates";
import { WALLET_END, WALLET_ERROR, WALLET_START, WALLET_TRANSACTIONS } from '../../actions/actions.types';


const walletReducer = (state = INIT_STATE_WALLET, action) => {
    switch (action.type) {
        case WALLET_END:
            return {
                ...state,
                wallet: action?.payload
            }
        case WALLET_TRANSACTIONS:
            return {
                ...state,
                transactions: action?.payload
            }
        default:
            return state;
    }

}

export default walletReducer