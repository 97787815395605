import { useState } from "react";
import { Content } from "./styled";
import Navbar from "components/Navbar";
import Footer from "components/MainFooter";
import Carousel from "components/carousel";
import { SideBar } from "components/sidebar";
import Badger from "components/carousel/Badge";
import { useLocation } from "react-router-dom";
import { useActivity } from 'hooks/useBusiness.js';
import { sidebarRoutes } from 'constants/sidebar';
import Checkout from "components/Navbar/Checkout";

const DashboardBoard = ({ children }) => {
  const [open, setOpen] = useState(false);
  const { state } = useActivity()
  const { pathname } = useLocation();
  const path = pathname.split('/');
  const advert = state?.adverts?.filter((data) => data?.medias[0].platform === 'web' && data?.medias[0].placement === 'bottom') ?? []
  return (
    <div>
      <Navbar routes={sidebarRoutes} open={open} setOpen={setOpen} />
      <Checkout link={{ to: pathname, title: path[1] }} />

      <div className="d-flex my-2 m-lg-4 px-lg-4">

        <SideBar />

        <Content className="px-4 w-100">{children}</Content>
      </div>

      {advert?.length > 0 ? <Carousel position="bottom" /> : <Badger />}
      <Footer />
    </div>
  );
};

const WithDashboardLayout = (Component) => {
  return (props) => <DashboardBoard children={<Component {...props} />} />;
};

export default WithDashboardLayout;
