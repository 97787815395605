import {
  ORDER,
  GET_CART,
  GET_HOMES,
  AUTH_START,
  UPDATE_CART,
  GET_ADDRESS,
  PRODUCT_END,
  ADD_ADDRESS,
  ADD_TO_CART,
  PRODUCTS_END,
  PRODUCTS_ERROR,
  PRODUCTS_START,
  GET_CATEGORIES,
  GET_FAVOURITES,
  DELETE_ADDRESS,
  REMOVE_FROM_CART,
  UPDATE_FAVOURITES,
  UNATHENTICATED_CART,
  REMOVE_FAVOURITES,
  PRODUCT_LOAD,
  CLEAR_CART,
  GET_ADVERTS,
  GET_COUPON,

} from "store/actions/actions.types";
import { INIT_STATE_BUSINESS } from "store/initialstates";
import { getDeliveryFee } from 'utils/helpers.js'

const businessReducer = (state = INIT_STATE_BUSINESS, action) => {
  switch (action.type) {
    case AUTH_START:
      return { ...state, loading: true };

    case PRODUCTS_START:
      return { ...state, loading: true, productLoading: true };

    case PRODUCTS_ERROR:
      return { ...state, loading: false ,  productLoading: false };

    case PRODUCTS_END:
      return { ...state, loading: false, products: action.payload, productLoading: false };

    case PRODUCT_LOAD:
      return { ...state, productLoading: true }

    case PRODUCT_END:
      return { ...state, loading: false, product: action.payload, productLoading: false };

    case GET_CATEGORIES:
      return {
        ...state, loading: false,
        categories: action.payload?.filter((data) => data?.visible === '1'),
        allCategories: action.payload
      };

    case GET_FAVOURITES:
      console.log(action.payload)
      return {
        ...state,
        favourites: action.payload,
        productLoading: false
      };

    case REMOVE_FAVOURITES:
      console.log(action.payload  , 'payload...')
      return {
        ...state,
        favourites: state.favourites.filter((data) => data?.uuid !== action.payload),
        products: state.products.map((data) => {
          if (action.payload?.product?.uuid === data.uuid) {
            data.isFavourite = false
          }
          return data
        })
      };

    case UPDATE_FAVOURITES:
      console.log(action.payload , 'favee')
      return {
        ...state,
        favourites:[ ...state.favourites.filter(data => data.product.uuid !== action.payload.product.uuid) , action.payload],
        products: state.products.map((data) => {
          if (action.payload?.product?.uuid === data.uuid) {
            data.isFavourite = action.payload?.product?.isFavourite;
          }
          return data
        })
      };

    case GET_COUPON:
      return {
        ...state,
        couponAmount: action.payload
      }

    case GET_HOMES:
      return { ...state, loading: false, homes: action.payload };

    case ADD_ADDRESS:
      return { ...state, loading: false, address: [...state.address, action.payload] }

    case ORDER:
      return { ...state, cart: [] }

    case CLEAR_CART:
      return { ...state, cart: [] }

    case ADD_TO_CART:
      const cart = state.cart ?? []

      const findProductIncart = cart.find((data) => data?.product?.uuid === action.payload?.product?.uuid);
      if (findProductIncart) {
        return {
          ...state,
          cart: cart.map((data) => {
            if (data.product.uuid === action.payload.product.uuid) {
              data.quantity = +data.quantity + +action.payload.quantity;
            }
            return data
          })
        };
      }
      return {
        ...state,
        cart: [...cart, action.payload]
      }
    case GET_ADVERTS:
      return {
        ...state,
        adverts: action.payload
      }
    case UNATHENTICATED_CART:

      const cartItems = state.cart ?? []
      const findProductIncartI = cartItems.find((data) => data?.product?.uuid === action.payload?.product?.uuid);
      if (findProductIncartI) {
        return {
          ...state,
          cart: cartItems.map((data) => {
            if (data.product.uuid === action.payload.product.uuid) {

              data.quantity = data.quantity + action.payload.quantity;
            }
            return data
          })
        };
      }
      return {
        ...state,
        cart: [...cartItems, action.payload]
      }
    case GET_ADDRESS:
      return { ...state, address: action.payload }

    case UPDATE_CART:

      return {
        ...state,
        cart: state.cart.map((data) => {
          if (data?.product?.uuid === action.payload?.product.uuid) {
            data.quantity = action.payload.value;
          }
          return data;
        })
      }
    // const findProduct = state.cart.find(({ product }) => product?.uuid === action.payload?.product.uuid);
    // findProduct.quantity = action.payload.value;  devliveryFee

    // const newCart = state.cart.filter(({ product }) => product?.uuid !== action.payload.product.uuid);
    // return { ...state, cart: [...newCart, findProduct], };

    case GET_CART:
      const { base_price, kg_price } = getDeliveryFee();
      const weight = action.payload?.items?.map((data) => +data.product.weight).reduce((a, b) => a + b);

      return {
        ...state,
        cart: action.payload?.items ?? action.payload,
        total: action.payload?.total,
        cartId: action.payload?.uuid,
        deliveryFee: (weight * action.payload?.items?.length) * +kg_price + +base_price
      };
    case DELETE_ADDRESS:
      return {
        ...state,
        address: state.address.filter((item) => item?.uuid !== action.payload),
        loading: false,
      }
    case REMOVE_FROM_CART:
      return { ...state, cart: state.cart.filter(({ product }) => product?.uuid !== action.payload.product.uuid) };
     
    default:
      return state;
  }
};

export default businessReducer;




