/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Suspense, useEffect, useState } from 'react';
import routes from './routes';
import { useActivity, useAuth, useWallet } from 'hooks';
import ProtectedRoutes from './protectedRoute'
import { LoadingIcon } from 'components/Loading'
import { Toaster } from 'react-hot-toast'
import { getCart } from 'utils/helpers';
import { getCarts } from 'services/carts';

const AppRouter = () => {

  const { fetchCart, fetchProducts, adds } = useActivity();
  const { stateAuth } = useAuth();
  const [res, setRes] = useState([]);

  const getUserCart = async () => {
    try {
      const responseData = await getCarts();
      return responseData?.data
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {

    fetchProducts();
    // fetchWallet();
    // fetchTransactions();
    // adds();
    if (!stateAuth.isAuthenticated) {
      fetchCart(getCart())
      return;
    } else {
      console.log(res)
      fetchCart(getUserCart())
      return;
    }
  }, [stateAuth.isAuthenticated])

  return (
    <Suspense fallback={<LoadingIcon fullscreen />}>
      <Router>
        <Routes>
          {routes.map((item, i) =>
            item?.protected ? (
              <Route
                key={i}
                element={<ProtectedRoutes Child={item?.component} />}
                path={item.path}
                exact={item?.exact}
              />
            ) : (
              <Route
                key={i}
                path={item.path}
                exact={item.exact}
                element={<item.component />}
              />
            ),
          )}
        </Routes>
        <Toaster
          position="top-center"
          toastOptions={{
            className: '',
            style: {
              margin: '30px',
              minWidth: '370px',
              maxHeight: '40px',
              padding: '20px',
              display: 'inline-flex',
              fontSize: '18px',
              zIndex: 999999,
            },
            duration: 4000,
            error: {
              style: {
                background: '#F55A5A',
                color: 'white',
              },
              iconTheme: {
                primary: 'white',
                secondary: '#F55A5A',
              },
            },
            success: {
              style: {
                background: 'green',
                color: 'white',
              },
              iconTheme: {
                primary: 'white',
                secondary: 'green',
              }
            }
          }}
        />
      </Router>
    </Suspense>
  )
}

export default AppRouter
