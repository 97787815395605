import { ReferWrapper, Badger } from './style';

import refer from 'assets/images/refer.svg';

const Container =  () => (
    <ReferWrapper id="badge">
        <Badger>
            <img src={refer} alt="" />
            <div className="detail">
                <h2>Refer a friend and get <span>10%</span> discount on your first purchase</h2>
                <button disabled>get started</button>
            </div>
        </Badger>
    </ReferWrapper>
);

export default Container;
