export const BASE_URL = 'https://api.cwayhomeweb.com/api/v1/';

export const LOGIN = [
    {
        name: "phone",
        type: "text",
        placeholder: "Phone number",
        required: true,
        className: "mt-3 w-100 py-4",
        inputClassName: 'ant-col-lg-16 ant-col-22',
        disabled: false,
        message: "Please enter your phone number",
        numb: 11,
    },
    {
        name: "password",
        type: "password",
        placeholder: "Your password",
        required: true,
        className: "  mt-2 w-100 py-2",
        disabled: false,
        inputClassName: 'ant-col-lg-16 ant-col-22',
        message: "Please enter your password with a minimum length of 6.",
        numb: 6,
    }
]

export const SIGN_UP = [

    {
        name: "name",
        type: "text",
        placeholder: "Name",
        required: true,
        className: "mt-3  w-100 py-4 ",
        inputClassName: 'ant-col-lg-10 ant-col-24',
        disabled: false,
        message: "Please enter your first name",
    },
    // {
    //     name: "lastname",
    //     type: "text",
    //     placeholder: "Last name",
    //     required: true,
    //     className: "mt-3 mx-lg-3  w-100 py-4 ",
    //     inputClassName: 'ant-col-lg-10 ant-col-24',
    //     disabled: false,
    //     message: "Please enter your last name",
    // },
    // {
    //     name: "username",
    //     type: "text",
    //     placeholder: "Username",
    //     required: true,
    //     className: "mt-3   w-100 py-4 ",
    //     inputClassName: 'ant-col-lg-10 ant-col-24',
    //     disabled: false,
    //     message: "Please enter your Username",
    // },
    {
        name: "phone",
        type: "number",
        placeholder: "Phone",
        required: true,
        className: "mt-3 w-100 py-4",
        inputClassName: 'ant-col-lg-10 ant-col-24 mx-lg-3',
        disabled: false,
        message: "Please enter your Phone number",
        numb: 11,
    },
    {
        name: "email",
        type: "text",
        placeholder: "Email (Optional)",
        required: false,
        className: "mt-3 w-100 py-4",
        inputClassName: 'ant-col-lg-10 ant-col-24',
        disabled: false,
        message: "Please enter your Email",
    },
    {
        name: "password",
        type: "password",
        placeholder: "Password",
        required: true,
        className: "mt-3 w-100 py-1",
        disabled: false,
        inputClassName: 'ant-col-lg-10 ant-col-24 mx-lg-3',
        message: "Please enter your password with a minimum length of 6.",
        numb: 6,
    },
    {
        name: "referral",
        type: "text",
        placeholder: "Enter Referral code",
        required: false,
        className: "  mt-3 w-100 py-4",
        disabled: false,
        inputClassName: 'ant-col-lg-10 ant-col-24',
        message: "",

    },
    {
        name: "confirm",
        type: "password",
        placeholder: "Confirm password",
        required: true,
        className: "mt-3 w-100 py-1",
        disabled: false,
        inputClassName: 'ant-col-lg-10 ant-col-24 mx-lg-3',
        message: "Please enter your password with a minimum length of 6.",
        numb: 6,
    },
]


export const OTP = [
    {
        name: "code",
        type: "text",
        placeholder: "Enter OTP",
        required: false,
        className: "  mt-2 w-100 py-4",
        disabled: false,
        inputClassName: 'ant-col-22',
        message: "",

    }
]

export const FORGOT_PASSWORD = [
    {
        name: "phone",
        type: "number",
        placeholder: "Enter Phone number",
        required: true,
        className: "mt-2 w-100 py-4",
        disabled: false,
        inputClassName: 'ant-col-22',
        message: "Please enter your phone number",
        numb: 11,

    }
]


export const RESET_PASSWORD = [
    {
        name: "code",
        type: "text",
        placeholder: "Enter OTP",
        required: true,
        className: "  mt-2 w-100 py-4",
        disabled: false,
        inputClassName: 'ant-col-24',
        message: "Please enter OTP",
        numb: 6,

    },
    {
        name: "password",
        type: "password",
        placeholder: "New Password",
        required: true,
        className: "  mt-2 w-100 py-2",
        disabled: false,
        inputClassName: 'ant-col-24',
        message: "Please enter your password with a minimum length of 6.",
        numb: 6,

    },
    {
        name: "confirm",
        type: "password",
        placeholder: "Confirm Password",
        required: true,
        className: "  mt-2 w-100 py-2",
        disabled: false,
        inputClassName: 'ant-col-24',
        message: "Please enter your password with a minimum length of 6.",
        numb: 6,

    }
]


export const COUPON = [
    {
        name: "coupon",
        type: "text",
        placeholder: "Coupon code",
        required: false,
        className: "coupon-form w-100 py-4 ps-3 pe-5 mx-0 mt-2",
        disabled: false,
        inputClassName: 'ant-col-40 me-1',
        message: "Please Enter your Coupon Code",
    }
]

export const BILLING_DETAILS = [

    {
        name: "firstname",
        type: "text",
        placeholder: "First name",
        required: true,
        className: "mt-3  w-100 py-4 ",
        inputClassName: 'ant-col-lg-10 ant-col-22',
        disabled: false,
        message: "Please enter your First name",
    },

    {
        name: "lastname",
        type: "text",
        placeholder: "Last name",
        required: true,
        className: "mt-3 mx-lg-3  w-100 py-4 ",
        inputClassName: 'ant-col-lg-10 ant-col-22',
        disabled: false,
        message: "Please enter your Last name",
    },
    {
        name: "Address1",
        type: "text",
        placeholder: "Address 1",
        required: true,
        className: "mt-3  w-100 py-2",
        inputClassName: 'ant-col-lg-10 ant-col-22',
        disabled: true,
        message: "Please enter your Address",
    },
    {
        name: "Address2",
        type: "text",
        placeholder: "Address 2",
        required: false,
        className: "mt-3 mx-lg-3 w-100 py-4 ",
        inputClassName: 'ant-col-lg-10 ant-col-22',
        disabled: false,
        message: "Please enter your second Address",
    },
    {
        name: "state",
        type: "text",
        placeholder: "State/country*",
        required: true,
        className: "mt-2  w-100 py-4 ",
        inputClassName: 'ant-col-lg-10 ant-col-22',
        disabled: false,
        message: "Please enter your State",
    },
    {
        name: "city",
        type: "text",
        placeholder: "City/Town*",
        required: true,
        className: "  mt-2 mx-lg-3 w-100 py-4 ",
        disabled: false,
        inputClassName: 'ant-col-lg-10 ant-col-22',
        message: "Please enter your City",
        numb: 8,
    },
    {
        name: "postcode",
        type: "text",
        placeholder: "Post Code/zip",
        required: true,
        className: "mt-2 w-100 py-4 ",
        disabled: false,
        inputClassName: 'ant-col-lg-10 ant-col-22',
        message: "Please enter your postcode",
        numb: 8,
    },
    {
        name: "phonenumber",
        type: "text",
        placeholder: "Phone Number",
        required: false,
        className: "mt-2 mx-lg-3 w-100 py-4",
        disabled: false,
        inputClassName: 'ant-col-lg-10 ant-col-22',
        message: "Please enter your Phone number",

    },
    {
        name: "email",
        type: "text",
        placeholder: "Email",
        required: true,
        className: "mt-2 w-100 py-4 ",
        inputClassName: 'ant-col-lg-10 ant-col-22',
        disabled: false,
        message: "Please enter your Email",
    },
    {
        name: "company",
        type: "text",
        placeholder: "Company",
        required: true,
        className: "mt-2 mx-lg-3 w-100 py-4 ",
        inputClassName: 'ant-col-lg-10 ant-col-22',
        disabled: false,
        message: "Please enter your company",
    },

    {
        name: "additional",
        type: "textarea",
        placeholder: "Additional Information",
        required: false,
        className: "mt-2 w-100 py-4 ",
        inputClassName: 'ant-col-lg-21 h-30 ant-col-22',
        disabled: false,
        message: "Please enter additional information",
    },
]


export const USER_INFO = [

    {
        name: "name",
        type: "text",
        label: "Name",
        placeholder: 'Name',
        required: true,
        className: "mt-3 w-100 py-4 ",
        inputClassName: 'ant-col-lg-20 ant-col-24',
        disabled: false,
        message: "Please enter your first name",
    },
    {
        name: "phone",
        type: "number",
        label: "Phone Number",
        placeholder: "Enter Phone number",
        required: true,
        className: "mt-2 w-100 py-4",
        disabled: true,
        inputClassName: 'ant-col-lg-20 ant-col-24',
        message: "Please enter your phone number",
        numb: 11,
    },
    // {
    //     name: "lastname",
    //     type: "text",
    //     label: "Last Name",
    //     placeholder: "Last name",
    //     required: true,
    //     className: "mt-3 mx-lg-1 w-100 py-4 ",
    //     inputClassName: 'ant-col-lg-10 ant-col-24',
    //     disabled: false,
    //     message: "Please enter your last name",
    // },
    {
        id: "dob",
        name: "dob",
        type: "date",
        label: "Date of Birth",
        placeholder: "Date of Birth",
        required: true,
        className: "mt-3 w-100 py-4 ",
        inputClassName: 'ant-col-lg-20 ant-col-24',
        disabled: false,
        message: "Please enter your dob",
    },
    // {
    //     id: "username",
    //     name: "username",
    //     type: "text",
    //     label: "Username",
    //     placeholder: "Username",
    //     required: true,
    //     className: "mt-3 w-100 py-4 ",
    //     inputClassName: 'ant-col-lg-20 ant-col-24',
    //     disabled: true,
    //     message: "Please enter your Username",
    // },
    {
        id: "email",
        name: "email",
        type: "text",
        label: "Email",
        placeholder: "example@mail.com",
        required: false,
        className: "mt-3 w-100 py-4",
        inputClassName: 'ant-col-lg-20 ant-col-24',
        disabled: false,
        message: "Please enter your Email",
    },
    // {
    //     id: "current-password",
    //     name: "current password",
    //     type: "password",
    //     label: "Current Password",
    //     placeholder: "Enter Current Password",
    //     required: true,
    //     className: "mt-3 w-100 py-1",
    //     disabled: false,
    //     inputClassName: 'ant-col-lg-20 ant-col-24',
    //     message: "Please enter your current password",

    // },
    // {
    //     id: "password",
    //     name: "password",
    //     type: "password",
    //     label: "New Password",
    //     placeholder: "Enter New Password",
    //     required: true,
    //     className: "mt-3 w-100 py-1",
    //     disabled: false,
    //     inputClassName: 'ant-col-lg-20 ant-col-24',
    //     message: "Please enter your new password",

    // },
    // {
    //     id: "confirm",
    //     name: "confirm",
    //     type: "password",
    //     label: "Confirm Password",
    //     placeholder: "Confirm Password",
    //     required: true,
    //     className: "  mt-3 w-100 py-1",
    //     disabled: false,
    //     inputClassName: 'ant-col-lg-20 ant-col-24',
    //     message: "Please confirm password",

    // }



].map((e, id) => ({ ...e, numb: id }))


export const REPAIR_FORM = [

    // {
    //     id: "service",
    //     name: "service",

    //     placeholder: "Enter the type of service you want",
    //     required: true,
    //     className: "mt-3 w-100 py-4",
    //     disabled: false,
    //     inputClassName: 'ant-col-22',
    //     message: "Service required",

    // },
    // {
    //     id: 'address',
    //     name: "address",

    //     placeholder: "Enter your address",
    //     required: true,
    //     className: "mt-3 w-100 py-4 ",
    //     inputClassName: 'ant-col-22',
    //     disabled: false,
    //     message: "Please enter an address",
    // },
    {
        name: "addres",
        type: "text",
        label: "",
        placeholder: "Enter your address",
        required: true,
        className: "mt-3 w-100 py-4 ",
        inputClassName: 'ant-col-22',
        disabled: false,
        message: "Please enter your first name",
    },
    {
        id: 'comment',
        name: "comment",
        type: "textarea",
        placeholder: "Enter Comments...",
        required: true,
        className: "mt-3 w-100 py-4",
        inputClassName: 'ant-col-22',
        disabled: false,
        message: "Please enter your comment",
    },

]

export const CHANGE_PASSWORD = [
    {
        id: "current-password",
        name: "old_password",
        type: "password",
        label: "Current Password",
        placeholder: "Enter Current Password",
        required: true,
        className: "mt-3 w-100 py-1",
        disabled: false,
        inputClassName: 'ant-col-lg-20 ant-col-24',
        message: "Please enter your current password which the minimum length is 6",
        numb: 6,

    },
    {
        id: "password",
        name: "password",
        type: "password",
        label: "New Password",
        placeholder: "Enter New Password",
        required: true,
        className: "mt-3 w-100 py-1",
        disabled: false,
        inputClassName: 'ant-col-lg-20 ant-col-24',
        message: "Please enter your password with a minimum length of 6",
        numb: 6,

    },
    {
        id: "confirm",
        name: "confirm",
        type: "password",
        label: "Confirm Password",
        placeholder: "Confirm Password",
        required: true,
        className: "  mt-3 w-100 py-1",
        disabled: false,
        inputClassName: 'ant-col-lg-20 ant-col-24',
        message: "Please enter your password with a minimum length of 6",
        numb: 6,

    }
]
