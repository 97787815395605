import styled from "styled-components";
import { Link } from "react-router-dom";

// HEADER - WRAPPER
export const HeaderContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  padding: 1rem 3.5%;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  /* position: -webkit-sticky; */
  /* position: sticky; */
  box-shadow: 0px 8px 10px -15px #000000;
  top: -0;
  z-index: 999;
  background-color: white;

  @media screen and (max-width: 40rem) {
    padding-inline: 10%;
  }
`;

// HEADER - LEFT
export const Logo = styled.div`
  flex: none;
  display: flex;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    height: 2.6rem;
    margin-left:1rem;
  }

  @media screen and (max-width: 40rem) {
    height: 2rem;
  }
`;

// HEADER - MIDDLE
export const SearchMenu = styled.div`
  display: flex;
  flex: 0 1 55%;
  flex-wrap: nowrap;

  @media (max-width: 65rem) {
    display: none;
  }
`;

// SEARCH CONTAINER
export const SearchContainer = styled.div`
  flex: 1 1 auto;
`;

// HEADER - RIGHT
export const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Lato';

    .sign {
    display: flex;
    cursor: pointer;
   
    position: relative;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
    margin-inline: 0.4rem;
    justify-content: center;
    font-size: clamp(0.8rem, 1vw, 2rem);
    i {
      font-style: normal;
      color: #7e7e7e;
      margin-left:-0.5rem;
      @media screen and (max-width: 40rem) {
        display: none;
      }
    }
    }

  .span {
    display: flex;
    cursor: pointer;
    /* margin-right: 5%; */
    position: relative;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
    margin-inline: 0.4rem;
    justify-content: center;
    font-size: clamp(0.8rem, 1vw, 2rem);

    img {
      width: 1.4rem;
      margin-right: 0.1rem;
    }

    i {
      font-style: normal;
      color: #7e7e7e;

      @media screen and (max-width: 40rem) {
        display: none;
      }
    }

    b {
      top: -30%;
      left: 0.8rem;
      color: white;
      display: flex;
      width: 1.1rem;
      height: 1.1rem;
      font-size: 0.6rem;
      border-radius: 50%;
      position: absolute;
      align-items: center;
      justify-content: center;
      font-variant: small-caps;
      background-color: #5d84c1;
      font-family: "Quicksand" sans-serif;
    }
  }
`;

export const HamburgerMenu = styled.div`
  border: none;
  display: flex;
  color: #253d4e;
  cursor: pointer;
  align-items: center;
  margin-left: -1.4rem;
  justify-content: center;
  background-color: transparent;

  @media (min-width: 40rem) {
    display: none;
  }

  span {
    display: inline-block;
  }
`;

export const UserDropDown = styled.nav`
  top: 3em;
  right: 0;
  gap: 0.3em;
  z-index: 1;
  width: 12em;
  display: flex;
  padding: 0.4em;
  cursor: initial;
  position: absolute;
  align-items: center;
  border-radius: 0.2em;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  transition: 400ms ease-in-out;
  opacity: ${({ show }) => Number(show)};
  box-shadow: 0.2em 0.2em 0.4em rgba(0 0 0 / 0.2);
  pointer-events: ${({ show }) => show ? 'all' : 'none'};
  transform: translateX(${({ show }) => show ? 0 : 2}em);

  a {
    width: 100%;
    color: black;
    font-weight: 900;
    padding: 0.5em 10%;
    border-radius: inherit;
    background-color: white;
    font-family: 'Quicksand';
    text-transform: capitalize;
    transition: 300ms ease-in-out;

    &:hover {
      color: white;
      background-color: #6d8dbf;
    }
  }
`;

// NAVBAR - WRAPPER
export const NavbarContainer = styled.nav`
  width: 100%;
  display: flex;
  padding: 0.4rem 4%;
  align-items: center;
  justify-content: space-between;
`;

export const NavbarCategory = styled.div`
  flex: none;
  padding: 0.4em;
  border-radius: 0.3rem;
  background-color: #6d8dbf;

  @media screen and (max-width: 40rem) {
    font-size: 0.4rem;
  }

  a {
    display: flex;
    font-size: 1.8em;
    align-items: center;
    justify-content: center;

    div {
      display: inherit;
      align-items: center;
      justify-content: center;
      padding: 0 0.3rem;
    }

    span {
      color: white;
      font-weight: 900;
      font-size: 0.8rem;

      @media screen and (max-width: 40rem) {
        font-size: 0.6em;
      }
    }
  }
`;

export const NavbarCon = styled.div`
  margin: 0 1%;
  display: flex;
  flex: 1  auto;
  overflow: hidden;
  padding: 2rem 0;
  align-items: center;
  transition: width 1s;
  justify-content: flex-start;
  
  @media (max-width:  768px) {
    width: ${(props) => props?.open ? '50%' : '0'};
    top: 4rem;
    height: fit-content;
    left: 0;
    z-index: 100;
    overflow: auto;
    transition: width 1s;
    position: absolute;
    border-radius: 0.3rem;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    box-shadow: 0 0 0.2rem rgba(0 0 0 / 0.18);

    .navside{
      /* gap: 0.5em; */
      display: flex;
      margin:0 1rem;
      color: #253d4e;
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 1em;
      align-items: center;
      justify-content: center;
      font-family: "quicksand";

      svg {
        fill: black;
      }

      img {
        filter: brightness(0);
      }
    }

    a {
      margin: 1rem 0 0;
      text-align: left;

      .ant-dropdown {

        background-color: red;

      }
    }

    &.open {
      width: 16rem;
      padding-left: 5%;
    }
  }
`;

export const MobileDiv = styled.div`

.hams {
  display: none;
}



@media (max-width: 768px) {


  .hams {
    display: block;
    margin-right: 10px;
  }

  .hams span {
    display: block;
    height: 3px;
    width: 20px;
    background-color: black;
  }

  .hams span:nth-child(2) {
    margin: 3px 0;
  }

  .hams-open span:nth-child(1) {
    transform: translateY(5px) rotate(45deg);
    transition: all 0.5s;
  }

  .hams-open span:nth-child(3) {
    opacity: 0;
  }

  .hams-open span:nth-child(2) {
    transform: rotate(-45deg);
    transition: all 0.5s;
  }
}
`

export const NavLink = styled(Link)`
  display: flex;
  color: #253d4e;
  font-weight: 900;
  white-space: nowrap;
  align-items: center;
  margin-inline: 0.8rem;
  justify-content: center;
  font-family: "quicksand";
  text-transform: capitalize;

  img {
    color: grey;
    margin-inline: 0.4rem;
  }
`;

export const DropdownLink = styled.a`
  color: #000;
  font-size: 18px;
  font-size: large;
  position: relative;

  span {
    font-family: "quicksand";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0004em;
    color: #253d4e;
  }

  .dropdown-icon {
    width: 0.5em;
    height: 0.5em;
  }
`;

export const ContactLink = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 40rem) {
    font-size: 0.6rem;
  }

  img {
    height: 1.4em;
    margin-inline: 0.4em;
  }

  .info {
    display: flex;
    flex: 0 1 auto;
    white-space: nowrap;
    padding: 0.3rem 0;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-start;

    h3 {
      margin: 0;
      padding: 0;
      color: #5d84c1;
      font-weight: 700;
      font-size: 1.4em;
      line-height: 2rem;
      display: inline-block;
      letter-spacing: -0.0004em;
    }

    span {
      color: #7e7e7e;
      font-weight: 400;
      font-family: "Lato";
      text-transform: capitalize;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.095em;
    }
  }
`;

// checkout header styling
export const CheckoutHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 1em 5%;
  align-items: center;
  background-color: #f5f5f5;
  justify-content: flex-start;

  a {
    color: #7e7e7e;
    margin-right: 1em;
    text-decoration: none;
    font-family: "Quicksand" sans-serif;
    text-transform: capitalize;

    &::after {
      /* content: ">"; */
      color: inherit;
      font-weight: 900;
      margin-left: 1em;
      pointer-events: none;
    }

    &:last-child {
      color: #5d84c1;

      &::after {
        display: none;
      }
    }
  }
`;

export const Btn = styled.button`
      color: white;
      border: none;
      outline: none;
      font-size: 1rem;
      cursor: pointer;
      font-weight: 700;
      letter-spacing: -0.0004em;
      position: relative;
      white-space: nowrap;
      padding: 0.6em 0.4em;
      border-radius: 0.313rem;
      background-color: #5D84C1;
      text-transform: capitalize;
      font-family: 'Quicksand';
      line-height: 1.25rem;

      @media screen and (max-width: 40rem) {
        font-size: 0.7rem;
      }

      &.open {
        cursor: initial;
      }

      svg {
        margin: 0 0.5em;
      }

      .modal {
        left: 0;
        z-index: 999;
        width: 22em;
        height: auto;
        padding: 1em;
        gap: 1em 0em;
        display: none;
        min-height: 8em;
        position: absolute;
        border-radius: 0.5em;
        top: calc(100% + 1em);
        background-color: white;
        box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.1);
        grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));

        span {
          height: 2.5em;
          display: flex;
          cursor: pointer;
          align-items: center;
          border-radius: 0.2em;
          padding-inline-start: 1em;
          background-color: #5D84C1;
          background-color: inherit;
          justify-content: flex-start;
          box-shadow: 0 0 0 0.05em rgba(0 0 0 / 0.1);

          &.add {
            box-shadow: none;
            grid-column: 1 / 3;
            justify-content: center;

            i {
              color: #7E7E7E;
              font-family: 'Quicksand';
              font-weight: 700;
              font-size: 0.875rem;
              line-height: 1.25rem;
              letter-spacing: -0.0004em;
            }
          }

          img,svg {
            width: 1.3em;
            height: 2em;
            stroke: #5D84C1;
            margin-right: 0.5em;
            object-fit: contain;
          }

          i {
            color: #253D4E;
            font-weight: 900;
            font-style: normal;
            text-transform: capitalize;
          }
        }
      }

      &.open .modal {
        display: grid;
      }
`;